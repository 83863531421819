<template>
  <div>
    <div>
      <div class="px-5 py-5">
        <div class="w-full mx-auto h-auto rounded-xl bg-white shadow-sm p-10 text-gray-800 relative overflow-hidden min-h-500">
          <div v-if="['TeleConsultant','CancelledOrder','Courier','ToRemind'].indexOf(currentUser.type) === -1" class="flex flex-col lg:flex-row mb-5"> 
                <div id="fulfillement" @click="activate('callcenters')" :class="{active: settings.callcenters}" class="intro-y flex-1 box-1 py-2 cursor-pointer zoom-in animated-button12">
                  <span></span>
                  <span></span>
                  <span></span>
                  <span></span>
                    <div class="check">
                        <div class="feather-icon select-none relative">
                            <svg xmlns="http://www.w3.org/2000/svg" width="24px" height="24px" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-check w-5 h-5 text-white bg-green-500 rounded-full shadow"><polyline points="20 6 9 17 4 12"></polyline></svg>
                        </div>
                    </div>
                    <img class="feather feather-shopping-bag block w-12 h-12 text-theme-1 dark:text-theme-10 mx-auto block w-12 h-12 text-theme-1 dark:text-theme-10 mx-auto" width="64" height="64" src="https://img.icons8.com/glyph-neue/64/technical-support.png" alt="external-delivery-man-logistics-delivery-kmg-design-detailed-outline-kmg-design-2"/>
                    <div class="text-xl font-medium text-center mt-5">Analytics call centers</div>
                </div>

                <div id="confirmation" @click="activate('livreurs')" :class="{active: settings.livreurs}" class="intro-y flex-1 box-1 py-2 lg:ml-5 mb-5 lg:mb-0 cursor-pointer zoom-in animated-button12">
                  <span></span>
                  <span></span>
                  <span></span>
                  <span></span>
                    <div class="check"><div class="feather-icon select-none relative"><svg xmlns="http://www.w3.org/2000/svg" width="24px" height="24px" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-check w-5 h-5 text-white bg-green-500 rounded-full shadow"><polyline points="20 6 9 17 4 12"></polyline></svg></div></div>
                    <img class="feather feather-shopping-bag block w-12 h-12 text-theme-1 dark:text-theme-10 mx-auto block w-12 h-12 text-theme-1 dark:text-theme-10 mx-auto" width="64" height="64" src="https://img.icons8.com/pastel-glyph/64/local-delivery--v1.png" alt="external-delivery-man-logistics-delivery-kmg-design-detailed-outline-kmg-design"/>
                    <div class="text-xl font-medium text-center mt-5">Analytics livreurs</div>
                </div>
                
            </div> 

          <template v-if="settings.callcenters || settings.livreurs">
                <div class="relative teleconsultant">
                  <div class="grid grid-cols-4 gap-4">
                    <v-select
                    v-if="['TeleConsultant','CancelledOrder','Courier','ToRemind'].indexOf(currentUser.type) === -1"
                      label="fullName"
                      :options="TabTeleConsultant"
                      class="input-style"
                      v-model="formData.teleConsultant"
                      :placeholder="settings.callcenters?$t('Select TeleConsultant'):$t('Select Livreurs')"
                      @input="setSelected"
                    >
                    </v-select>
                  
                    <select
                      id="type"
                      name="date"
                      autocomplete="type"
                      v-model="duree"
                      @change="ChangeTypeDate($event)"
                      class="input-style"
                    >
                      <option value="Today">{{$t('today')}}</option>
                      <option value="Yesterday">{{$t('yesterday')}}</option>
                      <option value="This Month">{{$t('this_month')}}</option>
                      <option value="Last Month">{{$t('last_month')}}</option>
                      <option value="This Year">{{$t('this_year')}}</option>
                      <option value="Last Year">{{$t('last_year')}}</option>
                    </select>
                    <date-picker placeholder="Range date ..." format="YYYY-MM-DD" type="date" class="w-full-imp input-style" v-model="formData.startDate" range></date-picker>
                    <!-- <input
                      :placeholder="$t('star_date')"
                      onfocus="(this.type='date')"
                      v-model="formData.startDate"
                      class="w-1/3 pl-3 pr-10 py-2 border-2 border-gray-200 rounded-xl hover:border-gray-300 focus:outline-none focus:border-blue-500 transition-colors"
                    />
                    <input
                      class="w-1/3 pl-3 pr-10 py-2 border-2 border-gray-200 rounded-xl hover:border-gray-300 focus:outline-none focus:border-blue-500 transition-colors"
                      :placeholder="$t('end_date')"
                      onfocus="(this.type='date')"
                      v-model="formData.endDate"
                    /> -->
                    <button
                      type="button"
                      @click="getTotalStatusPerTeleconsultantBetweenTwoDate"
                      class="border font-semiborder-indigo-500 bg-indigo-500 text-white rounded-md transition duration-500 ease select-none hover:bg-indigo-600 focus:outline-none focus:shadow-outline"
                    >
                      {{$t('apply')}}
                    </button>
                  </div>
                </div>
                <div class="absolute top-0 left-0 w-full h-2 flex">
                  <div class="h-1 bg-green-600  flex-1"></div>
                  <div class="h-1 bg-yellow-500 flex-1"></div>
                  <div class="h-1 bg-green-600  flex-1"></div>
                  <div class="h-1 bg-yellow-500 flex-1"></div>
                  <div class="h-1 bg-green-600  flex-1"></div>
                  <div class="h-1 bg-yellow-500 flex-1"></div>
                </div>
                <div class="p-4">
                  <div class="bg-white w-full">
                    <div class="antialiased bg-white">

                          <div class="h-20 flex items-center" v-if="['TeleConsultant','CancelledOrder','Courier','ToRemind'].indexOf(currentUser.type) === -1">
                            <div class="flex"><div class="text-2xl font-bold text-green-700   ">{{settings.callcenters?$t('teleconsultant'):$t('Livreurs')}}</div></div>
                            <div class="w-full flex justify-end">
                              <div :class="{'cursor-not-allowed pointer-events-none':TeleConsultantTab.length==0}" @click="downloadExcel('analytics')"  class="ml-2 cursor-pointer flex rounded focus:outline-none outline-none text-gray-100 px-3 w-1/7 py-2 bg-green-600  hover:shadow-inner hover:bg-green-700  transition-all duration-300"><i class="material-icons">file_download</i> csv </div>
                              </div>
                          </div>

                          <div  v-if="settings.callcenters && ['TeleConsultant','CancelledOrder','Courier','ToRemind'].indexOf(currentUser.type) === -1" class="w-full flex items-center justify-center">
                            <div class="w-1/2 flex">
                                  <div class="w-10 z-10 pl-1 text-center pointer-events-none flex items-center justify-center">
                                      <svg xmlns="http://www.w3.org/2000/svg" class="text-gray-600 fill-current" width="18" height="18" viewBox="0 0 24 24"><path d="M6 3l-6 8h4v10h4v-10h4l-6-8zm16 6h-8v-2h8v2zm2-6h-10v2h10v-2zm-4 8h-6v2h6v-2zm-2 4h-4v2h4v-2zm-2 4h-2v2h2v-2z"/></svg>
                                  </div>
                                    <v-select
                                      label="name"
                                      :disabled="loading"
                                      placeholder='Trier par'
                                      :options="dataSort"
                                      v-model="sortby"
                                      class="text-gray-600 bg-white w-full -ml-10 pl-8 pr-2 py-1 rounded-lg border-2 border-gray-200 outline-none focus:border-indigo-500"
                                  
                                  ></v-select>
                            </div>
                          </div>

                          <div
                            class="-mx-4 sm:-mx-8 px-4 sm:px-8 overflow-x-auto"
                          >
                            <div
                              class="inline-block min-w-full rounded-lg overflow-hidden relative"
                            >
                            <loading :active.sync="loading" :is-full-page="fullPage"></loading>

                            <div class="table-responsive custom-table-responsive">
                                <table class="table custom-table">
                                  <thead>
                                      <tr>
                                        <th scope="col" class="uppercase text-left">{{$t('user')}}</th>
                                        <!-- <th scope="col" class="uppercase text-center">{{$t('nb_order_traits')}}</th> -->
                                       
                                        <template v-if="settings.callcenters">
                                          <th scope="col" class="uppercase text-center">  {{$t('nb calls')}}</th>
                                          <th scope="col" class="uppercase text-center">{{$t('confirmed')}}</th>
                                          <th scope="col" class="uppercase text-center"> {{$t('cancelled')}}</th>
                                          <th scope="col" class="uppercase text-center"> {{$t('Orange money')}}</th>
                                          <th scope="col" class="uppercase text-center"> {{$t('to_remind')}}</th>
                                          <th scope="col" class="uppercase text-center"> {{$t('unreached')}}</th>
                                        </template>
                                       
                                        <th scope="col" class="uppercase text-center"> {{$t('Shipped')}}</th>
                                        <th scope="col" class="uppercase text-center"> {{$t('Reprogrammer')}}</th>
                                        <th scope="col" class="uppercase text-center"> {{$t('Paid')}}</th>
                                        <th scope="col" class="uppercase text-center"> {{$t('Processed')}}</th>
                                        
                                        <th scope="col" class="uppercase text-center"> {{$t('Return')}}</th>
                                      
                                        <th scope="col" class="uppercase text-center"></th>
                                      </tr>
                                    </thead>
                                    <tbody>
                                      <tr  v-for="(item, index) in TeleConsultantTab" :key="index" scope="row">
                                        <td class="border-t-b-l-r">
                                            <div class="flex">
                                              <div class="w-10 h-10 image-fit zoom-in">
                                                <div class="mr-3">
                                                      <a :class="'bg-'+getBgColor()" class="btn btn-circle btn-danger text-white uppercase">{{nameToInitials(DataParse(item.userData).fullName)}}</a>
                                                </div>
                                                </div>
                                                <div class="flex-wrap image-fit zoom-in">
                                                  <p class="flex w-full font-medium whitespace-nowrap uppercase">
                                                      <span v-if="DataParse(item.userData).fullName" >
                                                            {{ DataParse(item.userData).fullName }}
                                                      </span>
                                                      <span v-else>
                                                        <div class="lds-ellipsis"><div></div><div></div><div></div><div></div></div>
                                                      </span>
                                                  </p>
                                                </div>
                                              </div>
                                        </td>
                                        <!-- <td class="text-center border-t-b"> {{item.countAll }}</td> -->
                                        <td  v-if="settings.callcenters" class="text-center border-t-b bg-orders">
                                            <p class="text-gray-900 whitespace-no-wrap font-bold">
                                              <span class="mr-1">☎️</span> {{item.callAll }}
                                            </p> 
                                        </td>
                                        <td  v-for="(it, indx) in item.status.filter(el => settings.callcenters || (settings.livreurs && ['Shipped','Reprogrammer','Paid','Processed','Return'].indexOf(el.name) !== -1))" :key="indx" class="text-center" :class="[it.name=='Shipped'?'border-t-b-l':'border-t-b',['Processed','Shipped','Reprogrammer','Return','Paid'].indexOf(it.name) != -1?'bg-shippings':'bg-orders']">
                                            <span class="text-gray-900  rounded-full">
                                              <Progress  :strokeColor="getColor(it.name)" :radius="25" :strokeWidth="7" :value="getPercentege(it.name,it.count,item.callAll,item.callAll,item.countShipp)">
                                                <template v-slot:footer>
                                                  <p class="text-total-m mb-0 flex">Total: {{ it.count }} <span class="upsells ml-1" v-if="it.upsells && settings.callcenters">(<b><span class="mr-1">🛍️</span> Up-Sells: {{ it.upsells }}</b>)</span> </p>  
                                                </template>
                                              </Progress>
                                            </span>
                                        </td>
                                        <td class="border-t-b-l-r text-center"> 
                                            <!-- <a href="#" class="text-green-400  hover:text-green-700    underline">
                                              <router-link :class="$colors.actionTable" :to="{path: `/callcenter/analytics/view/${item.user}`,}"><i class="material-icons mx-1">remove_red_eye</i></router-link>
                                          </a> -->
                                          <a v-if="['TeleConsultant','CancelledOrder','Courier','ToRemind'].indexOf(currentUser.type) === -1" href="#" class="text-blue-400  hover:text-blue-700  underline" @click="Detials(item)"><i class="material-icons mx-1">remove_red_eye</i></a>
                                        </td>
                                      </tr>
                                      <tr class="spacer"><td colspan="100"></td></tr>
                                        <tr v-if="TeleConsultantTab.length>0 && ['TeleConsultant','CancelledOrder','Courier','ToRemind'].indexOf(currentUser.type) === -1">
                                            <td>{{$t('total')}} :</td>
                                            <!-- <td class="text-center">{{countOrderCancAndConf}}</td> -->
                                            <template v-if="settings.callcenters">
                                                <td class="text-center">{{countOrder}}</td>
                                              
                                                <td class="text-center">
                                                  <Progress  :strokeColor="getColor('Confirmed')" :radius="30" :strokeWidth="8" :value="getPercentege(null,countOrderConf,countOrderCan+countOrderConf,null)">
                                                          <template v-slot:footer>
                                                            {{ countOrderConf}}
                                                          </template>
                                                        </Progress>
                                                </td>
                                                
                                                <td class="text-center">
                                                  <Progress  :strokeColor="getColor('Cancelled')" :radius="30" :strokeWidth="8" :value="getPercentege(null,countOrderCan,countOrderConf+countOrderConf,null)">
                                                          <template v-slot:footer>
                                                            {{countOrderCan}} 
                                                          </template>
                                                    </Progress>
                                                </td>
                                                <td class="text-center">
                                                  <Progress  :strokeColor="getColor('Orange money')" :radius="30" :strokeWidth="8" :value="getPercentege(null,countTransfere,countOrderToRem+countOrderUnr+countTransfere,null)">
                                                          <template v-slot:footer>
                                                          {{countTransfere}} 
                                                          </template>
                                                    </Progress>
                                                </td>
                                                <td class="text-center">
                                                  <Progress  :strokeColor="getColor('ToRemind')" :radius="30" :strokeWidth="8" :value="getPercentege(null,countOrderToRem,countOrderToRem+countOrderUnr+countTransfere,null)">
                                                          <template v-slot:footer>
                                                          {{countOrderToRem}} 
                                                          </template>
                                                    </Progress>
                                                </td>
                                                <td class="text-center">
                                                    <Progress  :strokeColor="getColor('Unreached')" :radius="30" :strokeWidth="8" :value="getPercentege(null,countOrderUnr,countOrderToRem+countOrderUnr+countTransfere,null)">
                                                          <template v-slot:footer>
                                                          {{countOrderUnr}} 
                                                          </template>
                                                    </Progress>
                                                </td>
                                          </template>

                                            <td class="text-center">
                                              <Progress  :strokeColor="getColor('Shipped')" :radius="30" :strokeWidth="8" :value="getPercentege(null,countShipped,countShipped+countPaid+countProcessed+countReturn+countReprogrammer,null)">
                                                      <template v-slot:footer>
                                                        {{countShipped}} 
                                                      </template>
                                                </Progress>
                                            </td>
                                            <td class="text-center">
                                              <Progress  :strokeColor="getColor('Reprogrammer')" :radius="30" :strokeWidth="8" :value="getPercentege(null,countReprogrammer,countShipped+countPaid+countProcessed+countReturn+countReprogrammer,null)">
                                                      <template v-slot:footer>
                                                      {{countReprogrammer}} 
                                                      </template>
                                                </Progress>
                                            </td>
                                            <td class="text-center">
                                              <Progress  :strokeColor="getColor('Paid')" :radius="30" :strokeWidth="8" :value="getPercentege(null,countPaid,countShipped+countPaid+countProcessed+countReturn+countReprogrammer,null)">
                                                      <template v-slot:footer>
                                                      {{countPaid}} 
                                                      </template>
                                                </Progress>
                                            </td> 
                                            <td class="text-center">
                                              <Progress  :strokeColor="getColor('Processed')" :radius="30" :strokeWidth="8" :value="getPercentege(null,countProcessed,countShipped+countPaid+countProcessed+countReturn+countReprogrammer,null)">
                                                      <template v-slot:footer>
                                                      {{countProcessed}} 
                                                      </template>
                                                </Progress>
                                            </td>
                                            <td class="text-center">
                                                <Progress  :strokeColor="getColor('Return')" :radius="30" :strokeWidth="8" :value="getPercentege(null,countReturn,countShipped+countPaid+countProcessed+countReturn+countReprogrammer,null)">
                                                      <template v-slot:footer>
                                                      {{countReturn}} 
                                                      </template>
                                                </Progress>
                                            </td>
                                        
                                            <td></td>
                                        </tr>  
                                    </tbody>

                                </table>
                            </div>

                              <div
                                v-if="TeleConsultantTab.length <= 0"
                                class="bg-blue-100 py-8"
                              >
                                <span
                                  class="w-full flex justify-center text-center py-3 border-b border-gray-200 text-base"
                                >
                                  <p
                                    class="font-semibold flex text-green-500  whitespace-no-wrap"
                                  >
                                    <i class="material-icons mx-3">error</i>
                                    <span class>No data found</span>
                                  </p>
                                </span>
                              </div>
                            </div>
                          </div>
                      
                      
                    </div>
                  </div>
                </div>
          </template>
           

        </div>
      </div>
    </div>
     <!--Modal reporté shipping-->  
     <vue-modal-2   name="ModalInfoTeleconsulatant" :headerOptions="{title:`${settings.callcenters?'TeleConsultant':'Livreur'}: ${infoTeleconsultant.user.name}`}" :noFooter="true" modalSize="xl" @on-close="close()">
          <div class="w-full bg-white rounded-lg p-4 pt-0 pb-0">
                <div class="items-center">
                  <div v-if="settings.callcenters" class="grid grid-flow-col gap-6">
                  <div class="card card-block card-stretch card-height relative text-primary">
                    <loading :active.sync="loading" :is-full-page="fullPage"></loading>
                     <div class="card-body bg-primary-light rounded">
                        <div class="d-flex align-items-center justify-content-between">
                           <div class="rounded iq-card-icon bg-primary"><img class="img-call" src="https://img.icons8.com/ios-filled/64/FFFFFF/headset.png"/>
                           </div>
                           <div class="text-right">
                              <h2 class="mb-0"><span class="counter" style="visibility: visible;">{{getPercentege(null,infoTeleconsultant.confirmed,infoTeleconsultant.confirmed+infoTeleconsultant.cancelled)}}%</span></h2>
                              <h5 class="">Confirmation Rate</h5>
                           </div>
                        </div>
                        <div class="separator separator-dashed my-2"></div>
                        
                        <div class="d-flex flex-stack">
                        
												<!--begin::Section-->
												<div class="text-info-line fw-semibold fs-6 me-2 d-flex align-items-center">
                          <span class="bullet bullet-vertical d-flex align-items-center min-h-70px mh-100 me-4 bg-info-line"></span>
                          <span class="text-gray-900 fw-bolder fs-6 mr-1">{{infoTeleconsultant.confirmed}} </span> Confirmed
                        </div>
												<!--end::Section-->
												<!--begin::Statistics-->
												<div class="d-flex align-items-senter">
													<!--begin::Number-->
                          <div class="text-cancelled-line fw-semibold fs-6 me-2 d-flex align-items-center">
                            <span class="bullet bullet-vertical d-flex align-items-center min-h-70px mh-100 me-4 bg-cancelled-line"></span>
                            <span class="text-gray-900 fw-bolder fs-6 mr-1">{{infoTeleconsultant.cancelled}} </span> Cancelled
                        </div>
												</div>
												<!--end::Statistics-->
											</div>
                     </div>
                     
                  </div>
                  </div>
                <div class="flex">
                  <div @click="downloadExcel('history')" :class="dataExcel.loading?'noselect':''"  class="ml-2 w-1/5 flex justify-center ml-auto cursor-pointer flex rounded focus:outline-none outline-none text-gray-100 px-3 w-1/7 py-2 bg-green-600 hover:shadow-inner hover:bg-green-700 transition-all duration-300">
                       <loading :active.sync="dataExcel.loading" :is-full-page="fullPage"></loading>  <i class="material-icons">file_download</i> <span v-if="dataExcel.loading" class="inline-block loading-excel"> Exporting ({{timer}}/ {{paginate.total}}) <span>.</span><span>.</span><span>.</span> </span><span v-else>Excel</span>
                    </div>
                </div>
               
                </div>
                 <div-table
                  :config="configList"
                  :idWharhouse="idWharhouse"
                  :currentUser="currentUser"
                  :data="infoTeleconsultant.dataOrders"
                  :loading="infoTeleconsultant.loading"
                  :paginate="paginate"
                  @paginatation="paginatation"
                  @refresh="refresh"
                ></div-table>
          </div> 
    </vue-modal-2 > 
  </div>
</template>
<script>
import HeaderFilters from "@/components/Analytics/Cards/HeaderFilters.vue";
import chartCallcenter from "@/components/Analytics/Cards/chartCallcenter.vue";
import config from "./configStatus";
export default {
  components: {
    HeaderFilters,
    chartCallcenter,
  },
  props: {
     currentUser: { type: Object},
     idWharhouse: { type: Object}
   },
  data() {
    return {
      configList: config.list,
      formData: {
        teleConsultant: "",
        startDate: "",
        endDate: "",
      },
      infoTeleconsultant:{user:{name:"",id:null},dataOrders:[],loading:false,confirmed:0,cancelled:0},
      paginate: {
        total: 0,
        currentpage: 1,
        lastpage: 0,
        per_page: 0,
      },
      duree: "Today",
      TeleConsultantTab: [],
      teleconsultant: "",
      teleconsultantId: "",
      TabTeleConsultant: [],
      loading:false,
      fullPage: false,
      countOrder:0,
      countOrderConf:0,
      countOrderCan:0,
      countOrderUnr:0,
      countOrderToRem:0,
      countTransfere:0,
      countOrderCancAndConf:0,
      countShipped:0,
      countPaid:0,
      countProcessed:0,
      countReturn:0,
      countReprogrammer:0,
      dataExcel:{loading:false,data:[]},
      timer: 0,
      interval: null,
      sortby:{key:"callAll",name:"Nb calls"},
      dataSort:[{key:"callAll",name:"Nb calls"},{key:"confirmed",name:"Confirmed"},{key:"cancelled",name:"Cancelled"},{key:"transfere",name:"Orange money"},{key:"toremind",name:"ToRemind"}],
      settings:{callcenters:false,livreurs:false}
    };
  },
   watch: {
      'warhouseSelected': async function(oldVal, newVal) {
            
      },
      'sortby': function () {
        console.log('sortby------',this.sortby)
        if(this.sortby){
          console.log('TeleConsultantTab 11-------',this.TeleConsultantTab)
          this.loading = true
          setTimeout(() => (this.loading = false), 1200);

          this.TeleConsultantTab.sort((a, b) => a[this.sortby.key] > b[this.sortby.key] ? -1 : (a[this.sortby.key] > b[this.sortby.key] ? 1 : 0));
          console.log('TeleConsultantTab 22-------',this.TeleConsultantTab)
        }

      },
      'idWharhouse': async function(oldVal, newVal) {
          this.idWharhouse=oldVal;  
          this.TeleConsultantTab=[];
          this.settings={callcenters:false,livreurs:false};
          await this.getUser();
          await this.getUsers();
          let filterM={IntervaleDate: this.duree};
          if(this.teleconsultantId) filterM._id=this.teleconsultantId;
          if(this.settings.callcenters || this.settings.livreurs){
            await this.getTotalOrdersPerTeleConsultant(filterM);
            this.emptyCount();
            await this.getTotal();
          }
      },
      teleconsultantId: async function (newVal, oldVal) {
        await this.getTotalStatusPerTeleconsultant({_id: this.teleconsultantId,IntervaleDate:this.duree});
      },
      startDate: async function (newVal, oldVal) {
        console.log("HIII newVal", newVal);
        console.log("HIII old val", oldVal);
      },
  },
  async mounted() {
      this.settings={callcenters:false,livreurs:false};
      await this.getUser();
      this.duree = "Today";
      let filterM={IntervaleDate: "Today"};
      if(this.teleconsultantId) filterM._id=this.teleconsultantId;
      if(this.settings.callcenters || this.settings.livreurs){
          await this.getTotalOrdersPerTeleConsultant(filterM);
          this.emptyCount();
          await this.getTotal();
      }
     
  },
  computed: {
    warhouseSelected(){
        return this.$store.getters["wharhouse/warhouseSelected"]({user:_.pick(this.currentUser,['countries'])})
    },
   },
  methods: {
    activate:async function(el){
      if(!this.settings[el]){
        this.settings[el] = !this.settings[el];
        if(el === 'callcenters') this.settings['livreurs'] =false;
        else this.settings['callcenters'] =false;
        this.duree = "Today";
        this.formData= {teleConsultant: "",startDate: "",endDate: ""};
        await this.getTotalOrdersPerTeleConsultant({IntervaleDate: "Today"});
        this.emptyCount();
        await this.getTotal();
        await this.getUsers();
      }
    },
    downloadExcel:async function (name) {
        var htmls = "";
        var uri = 'data:application/vnd.ms-excel;base64,';
        var template = '<html xmlns:o="urn:schemas-microsoft-com:office:office" xmlns:x="urn:schemas-microsoft-com:office:excel" xmlns="http://www.w3.org/TR/REC-html40"><meta http-equiv="content-type" content="application/vnd.ms-excel; charset=UTF-8"><head><!--[if gte mso 9]><xml><x:ExcelWorkbook><x:ExcelWorksheets><x:ExcelWorksheet><x:Name>{worksheet}</x:Name><x:WorksheetOptions><x:DisplayGridlines/></x:WorksheetOptions></x:ExcelWorksheet></x:ExcelWorksheets></x:ExcelWorkbook></xml><![endif]--></head><body><table>{table}</table></body></html>'; 
        var base64 = function(s) {
            return window.btoa(unescape(encodeURIComponent(s)))
        };

        var format = function(s, c) {
            return s.replace(/{(\w+)}/g, function(m, p) {
                return c[p];
            })
        };

        let table =document.getElementById("p-table");

         if(name == 'history'){

            this.dataExcel.data=[];table='';
            this.dataExcel.loading=true;
            let filters={};
            filters.user= this.infoTeleconsultant.user.id;
            if(this.settings.livreurs) {
              filters.courier=this.infoTeleconsultant.user.id;
              delete filters.user;
            }
            filters.country= this.idWharhouse.country;
            filters.DateType= this.duree;
        
            if (this.formData.startDate && Array.isArray(this.formData.startDate) && this.formData.startDate.filter(function(el) { return el; }).length==2){
              filters.startDate=this.formData.startDate.map( el => this.$moment(el).format('yyyy-MM-DD'));
              filters.endDate=this.formData.endDate;
            } 
            
            // return false
          //  console.log('filter export to excell ----',filters)
            var itemsCounts=1000;
            filters.limit=itemsCounts;
            filters.page = 1;
            // filters['orderObjet.feesShipp']=-1;
            var count = Math.ceil(this.paginate.total/itemsCounts);
            var close=false;
            
            console.log('total ----',this.paginate.total)
            console.log('count  ----',count)
            for (var i = 0; i <count; i++) {
                if(close) return;
                const resAllshipp = await this.$server.search("statusHistories",filters);
                 console.log('resAllshipp export to excell ----',resAllshipp)
               // return false
                if(resAllshipp.content.results) {
                    this.timer+=resAllshipp.content.results.length;
                    filters.page++;
                    this.dataExcel.data.push(...resAllshipp.content.results);
                }else close=true  

                if(close)  {this.timer=0;this.dataExcel.loading=false;alert("An error occurred, please reload the page and try again !!", "error"); }
            }

            // const resAllshipp = await this.$server.search("shippings",filters);
            if(this.dataExcel.data.length>0) {
                this.timer=0;
                this.dataExcel.loading=false;
                console.log('dataExcel--------------',this.dataExcel.data)
                table=`<table id="p-table" class="table w-full text-left whitespace-no-wrap">
                <thead class="bg-gray-200">
                    <tr class="text-left">
                        <th class="border p-1 text-center ">${this.$t('order_id')}</th>
                        <th class="border p-1 text-center ">${this.$t('date')}</th>
                        <th class="border p-1 text-center ">${this.$t('time spent')}</th>
                        <th class="border p-2 text-center">${this.$t('Preivieus status') }</th>
                        <th class="border p-2 text-center">${this.$t('Change to') }</th>
                        <th class="border p-2 text-center">${this.$t('comment') }</th>
                    </tr>
                </thead>
                <tbody>`;
                if(this.dataExcel.data.length == 0){
                    table+=`<tr><th class="border p-3 bg-gray-100" colspan="12">${this.$t("no_record_found")}</th></tr>`;
                }    
                for (const item of this.dataExcel.data) {
                    table+= 
                    `<tr class="text-center">
                        <td class="border p-2  bg-green-100">${ item.order.id}</td>
                        <td class="border p-2  bg-green-100">${ this.$moment(item.date).format('DD-MM-YYYY')}</td>
                        <td class="border p-2"><span class="flex items-center">${ item.timeConsumed||'' }</span></td>
                        <td class="border p-2"><span class="flex items-center">${ item.previousStatus?item.previousStatus.name:'' }</span></td>
                        <td class="border p-2"><span class="flex items-center">${ item.status?item.status.name:'' }</span></td>
                        <td class="border p-2"><span class="flex items-center">${ item.comment||'' }</span></td>
                    </tr>`;
                }
                
                
                table+= `</tbody> </table>`;  
                let parser = new DOMParser();
                let doc = parser.parseFromString(table, 'text/html');
                table=doc.body;
            }else this.dataExcel.loading=false;

             
         }else{
             
          table=`<table id="p-table" class="table w-full text-left whitespace-no-wrap">
                <thead class="bg-gray-200">
                    <tr class="text-left">
                        <th class="border p-1 text-center ">USER</th>`;
                       if(this.settings.callcenters){
                        table+= `<th class="border p-1 text-center ">NB CALLS</th>
                            <th class="border p-1 text-center ">CONFIRMED	</th>
                            <th class="border p-2 text-center">CANCELLED</th>
                            <th class="border p-2 text-center">Orange money</th>
                            <th class="border p-2 text-center">TO REMIND</th>
                            <th class="border p-2 text-center">UNREACHED</th>`;
                       }
                      

                       table+= `<th class="border p-2 text-center">SHIPPED</th> 
                        <th class="border p-2 text-center">REPROGRAMMER</th>
                        <th class="border p-2 text-center">PAID</th>
                        <th class="border p-2 text-center">PROCESSED</th>
                        <th class="border p-2 text-center">RETURN</th>`;
                        if(this.settings.callcenters){
                          table+= `<th class="border p-1 text-center ">Up-Sells Call Center</th>
                           <th class="border p-1 text-center ">Up-Sells Delivery</th>`;
                        } 
                    table+= ` </tr>
                </thead>
                <tbody>`;
                if(this.TeleConsultantTab.length == 0){
                    table+=`<tr><th class="border p-3 bg-gray-100" colspan="${this.settings.callcenters?'12':'4'}">${this.$t("no_record_found")}</th></tr>`;
                }    

                for (const item of this.TeleConsultantTab) {
                    table+= 
                    `<tr class="text-center">
                        <td class="border p-2  bg-green-100"> ${  this.DataParse(item.userData).fullName||'' }</td>`;
                        if(this.settings.callcenters) table+=`<td class="border p-2  bg-green-100">${ item.callAll }</td>`;
                        for (const it of item.status.filter(el => this.settings.callcenters || (this.settings.livreurs && ['Shipped','Reprogrammer','Paid','Processed','Return'].indexOf(el.name) !== -1))) {
                          table+=`<td class="border p-2  bg-green-100"> ${ it.count }</td>`;
                        }
                        if(this.settings.callcenters){
                          table+=`<td class="border p-2  bg-green-100"> ${ item.status.find(el =>  el.name === 'Confirmed').upsells||0 }</td>`;
                          table+=`<td class="border p-2  bg-green-100"> ${ item.status.filter(el => ['Paid','Processed'].indexOf(el.name) !== -1).map(it => it.upsells).reduce((prev, curr) => prev + curr, 0) }</td>`;
                        }
                       
                     table+= `</tr>`;
                }
                
                
                table+= `</tbody> </table>`;  
                let parser = new DOMParser();
                let doc = parser.parseFromString(table, 'text/html');
                table=doc.body;

         }
            
      
        console.log('table html 22------',table)
        htmls =table.outerHTML
        var ctx = {
            worksheet : 'analyticsCall',
            table : htmls
        }

        var link = document.createElement("a"); 
        link.download = `analyticsCall-${this.infoTeleconsultant.user.name||this.$moment(Date.now()).format('DD-MM-YYYY')}.xls`;
        link.href = uri + base64(format(template, ctx));
        link.click();         
    },
    close(){
      this.$vm2.close('ModalInfoTeleconsulatant');
    },
    async Detials(data){
      this.infoTeleconsultant.user.name=this.DataParse(data.userData).fullName;
      console.log('data--------',data)
      
      this.infoTeleconsultant.user.id=data.user;
      this.infoTeleconsultant.confirmed=data.status.find(o => o.name === "Confirmed").count;
      this.infoTeleconsultant.cancelled=data.status.find(o => o.name === "Cancelled").count;
      //this.infoTeleconsultant.paid=data.status.find(o => o.name === "Paid").count;
      this.$vm2.open('ModalInfoTeleconsulatant');
      await this.getStatusHistories({});
    },
    async getStatusHistories(filters) {
        filters.user= this.infoTeleconsultant.user.id;
        if(this.settings.livreurs) {
          filters.courier=this.infoTeleconsultant.user.id;
          delete filters.user;
        }
        filters.country= this.idWharhouse.country;
        filters.DateType= this.duree;
        // if(this.sellerdata) filters.seller=this.sellerdata._id||this.sellerdata;
        // if(this.productdata) filters.product=this.productdata._id||this.productdata;
        
        if (this.formData.startDate && Array.isArray(this.formData.startDate) && this.formData.startDate.filter(function(el) { return el; }).length==2){
          filters.startDate=this.formData.startDate.map( el => this.$moment(el).format('yyyy-MM-DD'));
          filters.endDate=this.formData.endDate;
        } 
        this.infoTeleconsultant.loading=true;
        console.log('filters  statu his----',filters)
        const res = await this.$server.search("statusHistories", filters);
        this.infoTeleconsultant.loading=false;
        if (res && res.content.results) {
            this.infoTeleconsultant.dataOrders = res.content.results;
            this.paginate.total = res.content.total;
            this.paginate.lastpage = res.content.last_page;
            this.paginate.per_page = res.content.per_page;
            this.paginate.currentpage = res.content.current_page;
            for(let i in this.infoTeleconsultant.dataOrders){
              this.infoTeleconsultant.dataOrders[i].order_id=this.infoTeleconsultant.dataOrders[i].order.id;
            }
        } else{ this.infoTeleconsultant.dataOrders = [];this.pagination={page:1,end:false}; this.paginate={total: 0,currentpage: 1,lastpage: 1,per_page: 0,}};
       
        console.log('this content ststus__',this.infoTeleconsultant.dataOrders )
    },
    async paginatation(direction) {
      var page = 0;
      if (direction == "next") {
        page = ++this.paginate.currentpage;
      } else {
        page = --this.paginate.currentpage;
      }
      const filters = {
        page: page,
        limit: 10,
      };
      this.getStatusHistories(filters);
    },
    async refresh(){

    },
    getColor(name){
       if(name=="Confirmed") return "#73d641";
       else if(name=="Cancelled" || name=='Return') return "#FF00AA";
       else if(name=="Cancelled validated") return "rgb(238, 9, 9)";
       else if(name=="Unreached") return "#af7603";
       else return "#5134d3"
    },
    DataParse(data){
      if (typeof data !== 'undefined') {
        return JSON.parse(data);
      }
      return ''; 
  },
  getBgColor(){
       var colors = ['gray-400', 'red-500', 'yellow-500', 'green-600', 'blue-600',"pink-600"];

       return  colors[Math.floor(Math.random() * colors.length)];
  },
  async getUser() {
      // const res = await this.$server.me("users");
      // if (res.content) this.currentUser = res.content;
      // else this.currentUser = {};
      if(['TeleConsultant','CancelledOrder','Courier','ToRemind'].indexOf(this.currentUser.type) !== -1){
        if(['TeleConsultant','CancelledOrder','ToRemind'].indexOf(this.currentUser.type) !== -1)  this.settings.callcenters=true;
        else this.settings.livreurs=true;
        this.teleconsultantId = this.currentUser._id;
      } else this.teleconsultantId=null;

  },
  nameToInitials(fullName) {
    if (typeof fullName !== 'undefined' && fullName) {
      const namesArray = fullName.trim().split(' ');
      if (namesArray.length === 1) return `${namesArray[0].charAt(0)}`;
      else return `${namesArray[0].charAt(0)}${namesArray[namesArray.length - 1].charAt(0)}`;
    }else return fullName;
  },
  getTotalorderUser(data){
     let count=0;
     for(let i in data){
       count+=data[i].count;
     }
     return count;
  },
  getTotalorderConfAndCancUser(data){
    
     let count=0;
         for(let i in data){
            if(data[i].name == "Confirmed" || data[i].name =="Cancelled"){
               count+=data[i].count;
          }
      }
     
    
     return count;
  },
  getPercentege(name=null,countStatus,total,call=null,shipp=null){
   
     if(name && ['ToRemind','Unreached','Confirmed','Cancelled'].indexOf(name) != -1) total=call;
     if(name && ['Processed','Shipped','Reprogrammer','Return','Paid'].indexOf(name) != -1) total=shipp;
     return total?Math.round((countStatus * 100) /  total):0;
  },
  emptyCount(){
      this.countOrder=this.countOrderConf=this.countOrderCan=this.countOrderUnr=this.countOrderToRem=this.countTransfere=this.countShipped=this.countPaid=this.countProcessed=this.countReturn=this.countReprogrammer=0
  },
    ChangeTypeDate: async function (event) {
      console.log('this.duree',event.target.value)
      switch (event.target.value) {
        case "Today":
          this.TeleConsultantTab = [];
          this.formData.startDate = "";
          this.formData.endDate = "";
          if (this.teleconsultantId) {
            await this.getTotalOrdersPerTeleConsultant({
              _id: this.teleconsultantId,
              IntervaleDate: "Today",
            });
          } else {
            await this.getTotalOrdersPerTeleConsultant({
              IntervaleDate: "Today",
            });
          }
          break;
        case "Yesterday":
          this.TeleConsultantTab = [];
          this.formData.startDate = "";
          this.formData.endDate = "";
          if (this.teleconsultantId) {
            await this.getTotalOrdersPerTeleConsultant({
              IntervaleDate: "Yesterday",
              _id: this.teleconsultantId,
            });
          } else {
            await this.getTotalOrdersPerTeleConsultant({
              IntervaleDate: "Yesterday",
            });
          }
          break;
        case "This Month":
          this.TeleConsultantTab = [];
          this.formData.startDate = "";
          this.formData.endDate = "";
          if (this.teleconsultantId) {
            await this.getTotalOrdersPerTeleConsultant({
              IntervaleDate: "This Month",
              _id: this.teleconsultantId,
            });
          } else {
            await this.getTotalOrdersPerTeleConsultant({
              IntervaleDate: "This Month",
            });
          }
          break;
        case "Last Month":
          this.TeleConsultantTab = [];
          this.formData.startDate = "";
          this.formData.endDate = "";
          if (this.teleconsultantId) {
            await this.getTotalOrdersPerTeleConsultant({
              IntervaleDate: "Last Month",
              _id: this.teleconsultantId,
            });
          } else {
            await this.getTotalOrdersPerTeleConsultant({
              IntervaleDate: "Last Month",
            });
          }
          break;

        case "This Year":
          this.TeleConsultantTab = [];
          this.formData.startDate = "";
          this.formData.endDate = "";
          if (this.teleconsultantId) {
            await this.getTotalOrdersPerTeleConsultant({
              IntervaleDate: "This Year",
              _id: this.teleconsultantId,
            });
          } else {
            await this.getTotalOrdersPerTeleConsultant({
              IntervaleDate: "This Year",
            });
          }
           console.log('telecosultant',this.TeleConsultantTab)
          break;

        case "Last Year":
          this.TeleConsultantTab = [];
          this.formData.startDate = "";
          this.formData.endDate = "";
          if (this.teleconsultantId) {
            await this.getTotalOrdersPerTeleConsultant({
              IntervaleDate: "Last Year",
              _id: this.teleconsultantId,
            });
          } else {
            await this.getTotalOrdersPerTeleConsultant({
              IntervaleDate: "Last Year",
            });
          }
          break;

        case "Two Date":
          this.TeleConsultantTab = [];
          await this.getTotalStatusPerTeleconsultantBetweenTwoDate();
          break;

        default:
          this.TeleConsultantTab = [];
          this.duree = "Today";
          this.formData.startDate = "";
          this.formData.endDate = "";
          if (this.teleconsultantId) {
            await this.getTotalOrdersPerTeleConsultant({
              IntervaleDate: "Today",
              _id: this.teleconsultantId,
            });
          } else {
            await this.getTotalOrdersPerTeleConsultant({
              IntervaleDate: "Today",
            });
          }
      }

      console.log('telecon____________',this.TeleConsultantTab)
    },
 getTotal(){
         for(let i in this.TeleConsultantTab) {
               this.countOrder += this.getTotalorderUser(this.TeleConsultantTab[i].status);
             // this.countOrder += this.TeleConsultantTab[i].count;
              for(let j in this.TeleConsultantTab[i].status) {
                if(this.TeleConsultantTab[i].status[j].name=="Confirmed")  this.countOrderConf += this.TeleConsultantTab[i].status[j].count;
                if(this.TeleConsultantTab[i].status[j].name=="Cancelled") this.countOrderCan += this.TeleConsultantTab[i].status[j].count;
                if(this.TeleConsultantTab[i].status[j].name=="Unreached") this.countOrderUnr += this.TeleConsultantTab[i].status[j].count;
                if(this.TeleConsultantTab[i].status[j].name=="ToRemind") this.countOrderToRem += this.TeleConsultantTab[i].status[j].count;
                if(this.TeleConsultantTab[i].status[j].name=="Orange money") this.countTransfere += this.TeleConsultantTab[i].status[j].count;

               if(this.TeleConsultantTab[i].status[j].name=="Shipped") this.countShipped += this.TeleConsultantTab[i].status[j].count;
               if(this.TeleConsultantTab[i].status[j].name=="Paid") this.countPaid += this.TeleConsultantTab[i].status[j].count;
               if(this.TeleConsultantTab[i].status[j].name=="Processed") this.countProcessed += this.TeleConsultantTab[i].status[j].count;
               if(this.TeleConsultantTab[i].status[j].name=="Return") this.countReturn += this.TeleConsultantTab[i].status[j].count;
               if(this.TeleConsultantTab[i].status[j].name=="Reprogrammer") this.countReprogrammer += this.TeleConsultantTab[i].status[j].count;
              }
        }
          this.countOrderCancAndConf = this.countOrderConf + this.countOrderCan ;
    },
   async downloadAnalytics(){
      console.log('Aalytic in json_____',this.TeleConsultantTab)
      var arr = typeof this.TeleConsultantTab !== 'object' ? JSON.parse(this.TeleConsultantTab) : this.TeleConsultantTab;
       for (let i in arr) {
            arr[i].User=this.DataParse(arr[i].userData).fullName
            arr[i].TotalCalls=arr[i].status[0].count+arr[i].status[1].count+arr[i].status[2].count+arr[i].status[3].count+arr[i].status[4].count;
            arr[i].Confirmed=arr[i].status[0].count;
            arr[i].Cancelled =arr[i].status[1].count;
            arr[i].Transferer =arr[i].status[2].count;
            arr[i].ToRemind =arr[i].status[3].count;
            arr[i].Unreached =arr[i].status[4].count;
            arr[i].TotalShippings =arr[i].status[5].count+arr[i].status[6].count+arr[i].status[7].count+arr[i].status[8].count;
       }
       for (let i in arr) {
            delete arr[i].user;
            delete arr[i].status;
            delete arr[i]._id;
            delete arr[i].count;
            delete arr[i].countAll;
            delete arr[i].callAll;
            delete arr[i].countShipp;
            delete arr[i].userData;
        }
      var str =
        `${Object.keys(arr[0])
          .map((value) => `"${value}"`)
          .join(',')}` + '\r\n';
      var csvContent = arr.reduce((st, next) => {
        st +=
          `${Object.values(next)
            .map((value) => `"${value}"`)
            .join(',')}` + '\r\n';
        return st;
      }, str);
      var element = document.createElement('a');
      element.href = 'data:text/csv;charset=utf-8,' + encodeURI(csvContent);
      element.target = '_blank';
      element.download = 'exportCallcenters.csv';
      element.click();
     
      if (this.teleconsultantId) {
      await this.getTotalOrdersPerTeleConsultant({
        IntervaleDate: this.duree,
        _id: this.teleconsultantId,
      });
    } else {
      await this.getTotalOrdersPerTeleConsultant({
        IntervaleDate: this.duree,
      });
    }
    },
    setSelected(teleconsultant) {
      if (teleconsultant) {
        this.teleconsultantId = teleconsultant._id;
      } else {
        this.teleconsultantId = "";
      }
    },
    async getUsers() {
    
      if((this.settings.callcenters || this.settings.livreurs) && ['TeleConsultant','CancelledOrder','Courier','ToRemind'].indexOf(this.currentUser.type) === -1){
          let type= ["TeleConsultant","CancelledOrder",'ToRemind'];
          if(this.settings.livreurs) type=["Courier"];

          const filters = {
            type: type,
            countries: await this.warhouseSelected,
          };
          const res = await this.$server.search("users", filters);
          if (res && res.content.results) {
            this.TabTeleConsultant = res.content.results;
            this.TabTeleConsultant.unshift("All");
          } else this.TabTeleConsultant = [];
      }

    },
    async getTotalOrdersPerTeleConsultant(filters) { 
      this.loading=true;
      filters['country']= await this.warhouseSelected;
      filters['settings']=this.settings;
      const res = await this.$server.getTotalOrdersPerTeleConsultant(
        "analytics",
        filters
      );
       this.loading=false;

      if (res.content.content) {
        this.TeleConsultantTab = res.content.content;
        console.log('TeleConsultantTab----------',this.TeleConsultantTab)
        for (let i in this.TeleConsultantTab) {
              let userData={fullName:"System"};
              if(this.TeleConsultantTab[i].user != "System"){
                  const getUser=await this.getUserById(this.TeleConsultantTab[i].user);
                  console.log('getUser',getUser)
                  userData={fullName:getUser.fullName||getUser.email};
               } 
              
              this.TeleConsultantTab[i].userData= JSON.stringify(userData);
          }
      }
      else this.TeleConsultantTab = [];
       this.emptyCount();
      await this.getTotal();
    },
    async getUserById(id){
        const editData = await this.$server.get("users", {id: id});
        return editData.content;
    },
    async getTotalStatusPerTeleconsultant(filters) {
       this.loading=true;
       this.TeleConsultantTab = [];
       filters['country']= await this.warhouseSelected;
       filters['settings']=this.settings;
       const res = await this.$server.getTotalOrdersPerTeleConsultant("analytics",filters);
       this.loading=false;
       console.log('res.content-------',res.content)
       if (res.content.content) {
        this.TeleConsultantTab = res.content.content;
         for (let i in this.TeleConsultantTab) {
              let userData={fullName:"System"};
              if(this.TeleConsultantTab[i].user != "System"){
                  const getUser=await this.getUserById(this.TeleConsultantTab[i].user);
                 userData={fullName:getUser.fullName||getUser.email};
               } 
              
              this.TeleConsultantTab[i].userData= JSON.stringify(userData);
          }
      }else this.TeleConsultantTab = [];
      console.log('count res',this.TeleConsultantTab)
       this.emptyCount();
      await this.getTotal();
    },
    async changeData() {
      this.duree = "";
      this.TabTeleConsultant = [];
    },
    async getTotalStatusPerTeleconsultantBetweenTwoDate() {
      let filters={country:await this.warhouseSelected};
      filters['settings']=this.settings;
      if (this.teleconsultantId) filters._id=this.teleconsultantId;
      this.duree = "Two Date";
     

      if (this.formData.startDate && Array.isArray(this.formData.startDate) && this.formData.startDate.filter(function(el) { return el; }).length==2){
          this.TeleConsultantTab = [];
          this.loading=true;
          filters.startDate= this.formData.startDate.map( el => this.$moment(el).format('yyyy-MM-DD'));
          const res = await this.$server.getTotalStatusPerTeleconsultantBetweenTwoDate("analytics",filters);
          this.loading=false;

          if (res && res.content.length>0) {
            this.TeleConsultantTab = res.content;
            for (let i in this.TeleConsultantTab) {
                  let userData={fullName:"System"};
                  if(this.TeleConsultantTab[i].user != "System"){
                      const getUser=await this.getUserById(this.TeleConsultantTab[i].user);
                      console.log('getUser',getUser)
                      userData={fullName:getUser.fullName||getUser.email};
                  } 
                  
                  this.TeleConsultantTab[i].userData= JSON.stringify(userData);
              }
          }else this.TeleConsultantTab = [];

      }
       this.emptyCount();
       await this.getTotal();
    },

    async getOrdersX() {
      this.duree = "Two Date";
      this.TeleConsultantTab = [];
    },
  },
 
};
</script>
<style scoped>

/* .min-h-500{
  min-height: 500px;
} */
.teleconsultant{
  border: 2px solid #959292;
  border-style: dashed;
  padding: 20px;
}
.bg-orders{
    background: #E7EEE9 !important;
    background: linear-gradient(0deg, #E7EEE9, #DFF55F)  !important;
}
.bg-shippings{
  background: #E7EEE9 !important;
    background: linear-gradient(0deg, #E7EEE9, #f5a85f) !important;
}
.border-t-b-r{
  border: 1px solid black;
  border-left: none;
}
.image-fit{
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
}
.border-t-b-l{
  border: 1px solid black;
  border-right: none;
}
.border-t-b-l-r{
  border: 1px solid black;
}
.border-t-b{
  border: 1px solid black;
  border-left: none;
  border-right: none;
}

table{
  border-collapse: separate;
  border-spacing: 0 7px;
}
.btn-circle {
    border-radius: 100%;
    width: 40px;
    height: 40px;
    padding: 5px;
    line-height: 30px;
    display: inline-block;
    text-align: center;
    vertical-align: middle;
    user-select: none;
    border: 1px solid transparent;
    font-size: .875rem;
}
.table-responsive {
    display: block;
    width: 100%;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
}

.table {
    width: 100%;
    color: #212529;
}
.custom-table thead tr, .custom-table thead th {
    border-top: none;
    border-bottom: none !important;
}
.table thead th {
    vertical-align: bottom;
    border-bottom: 2px solid #dee2e6;
}
.table th, .table td {
    padding: 0.75rem;
}
.custom-table tbody tr:not(.spacer) {
    border-radius: 7px;
    overflow: hidden;
    -webkit-transition: .3s all ease;
    -o-transition: .3s all ease;
    transition: .3s all ease;
}
.custom-table tbody tr.spacer td {
    padding: 0 !important;
    height: 10px;
    border-radius: 0 !important;
    background: transparent !important;
}
.custom-table tbody tr th:last-child, .custom-table tbody tr td:last-child {
    border-top-right-radius: 7px;
    border-bottom-right-radius: 7px;
}
.custom-table tbody tr th:first-child, .custom-table tbody tr td:first-child {
    border-top-left-radius: 7px;
    border-bottom-left-radius: 7px;
}
.custom-table tbody tr th, .custom-table tbody tr td {
    background: #f1f0f0;
}
.custom-table tbody th, .custom-table tbody td {
    padding-bottom: 5px;
    padding-top: 5px;
    font-weight: 600;
}
.table th, .table td {
    padding: 0.75rem;
}
.lds-ellipsis {
    display: inline-block;
    position: relative;
    width: 83px;
    height: 40px;
    margin-right: auto;
}
.lds-ellipsis div {
  position: absolute;
  top: 33px;
  width: 13px;
  height: 13px;
  border-radius: 50%;
  background: #ff5b2d;
  animation-timing-function: cubic-bezier(0, 1, 1, 0);
}
.lds-ellipsis div:nth-child(1) {
  left: 8px;
  animation: lds-ellipsis1 0.6s infinite;
}
.lds-ellipsis div:nth-child(2) {
  left: 8px;
  animation: lds-ellipsis2 0.6s infinite;
}
.lds-ellipsis div:nth-child(3) {
  left: 32px;
  animation: lds-ellipsis2 0.6s infinite;
}
.lds-ellipsis div:nth-child(4) {
  left: 56px;
  animation: lds-ellipsis3 0.6s infinite;
}
@keyframes lds-ellipsis1 {
  0% {
    transform: scale(0);
  }
  100% {
    transform: scale(1);
  }
}
@keyframes lds-ellipsis3 {
  0% {
    transform: scale(1);
  }
  100% {
    transform: scale(0);
  }
}
@keyframes lds-ellipsis2 {
  0% {
    transform: translate(0, 0);
  }
  100% {
    transform: translate(24px, 0);
  }
}

.bg-info {
    color: #fff !important;
    background-color: #876cfe !important;
}
.card-block.card-height {
    height: calc(100% - 30px);
}
.card-block.card-stretch {
    -webkit-box-align: stretch;
    -ms-flex-align: stretch;
    align-items: stretch;
}
.card {
    border: none;
    position: relative;
    background: #fff;
    -webkit-border-radius: 8px;
    -moz-border-radius: 8px;
    -ms-border-radius: 8px;
    -o-border-radius: 8px;
    border-radius: 8px;
    margin-bottom: 30px;
    -webkit-box-shadow: 0px 16px 40px 0px #0000000a;
    -moz-box-shadow: 0px 16px 40px 0px #0000000A;
    -ms-box-shadow: 0px 16px 40px 0px #0000000A;
    -o-box-shadow: 0px 16px 40px 0px #0000000A;
    box-shadow: 0px 16px 40px 0px #0000000a;
    min-width: 0;
    word-wrap: break-word;
}
.card-block {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-flex: 1;
    -ms-flex-positive: 1;
    flex-grow: 1;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    box-shadow: 0 13px 40px rgb(198 197 202);
    border-bottom: 4px solid;
}
.text-primary {
    color: #05bbc9 !important;
}
.text-info {
    color: #876cfe !important;
}
.card-block img {
  padding: 8px;
}
.card-block h2 {
    font-weight: 500;
    margin: 0px;
    line-height: 1.5;
    color: #303030;
    font-size: 2.3em;
}
.card-block  h5 {
    font-weight: 500;
    margin: 0px;
    line-height: 1.5;
    color: #303030;
    font-size: 1.1em;
}
.bg-info-line {
    background-color: #15a814;
}
.bg-cancelled-line{
  background-color: #e30b0b;
}
.text-info-line {
    color: #15a814;
}
.text-cancelled-line{
  color: #e30b0b;
}

.bg-trait-line{
  background-color: #4b5668;
}
.bg-paid-line{
  background-color: #966a0e;
}
.bullet-vertical {
    width: 4px;
    height: 8px;
    border-radius: 6px;
    flex-shrink: 0;
    min-height: 25px;
    margin-right: 10px;
    max-height: 100%;
}
  .flex-stack {
    justify-content: space-between;
    align-items: center;
}
.fw-semibold {
    font-weight: 500;
}
.separator.separator-dashed {
    border-bottom-style: dashed;
    border-bottom-color: #04bbc9;
}
.separator-paid{
    border-bottom-color: #876cff !important;
}
.separator {
    display: block;
    height: 0;
    border-bottom: 1px solid #eff2f5;
}
.flex-stack {
    justify-content: space-between;
    align-items: center;
}
.bg-info-light {
    color: #2900da !important;
    background-color: #e1daff !important;
}
.rounded {
    border-radius: 8px !important;
}
.card-body {
    flex: 1 1 auto;
    min-height: 1px;
    padding: 1.25rem;
    padding-bottom: 10px;
    padding-top: 10px;
}
.align-items-center {
    align-items: center !important;
}
.justify-content-between {
    justify-content: space-between !important;
}
.d-flex {
    display: flex !important;
}
.iq-card-icon {
    height: 60px;
    width: 60px;
    display: inline-block;
    line-height: 60px;
    text-align: center;
    font-size: 22px;
}
.rounded {
    border-radius: 8px !important;
}
.bg-primary-light {
    color: #27a6b1 !important;
    background-color: #dff6f8 !important;
}
.bg-primary {
    color: #fff !important;
    background-color: #05bbc9 !important;
}
.box {
    border-color: transparent;
    border-radius: .375rem;
    position: relative;
}
.text-xs {
  font-size: .60rem;
}


.animated-button span {
  position: absolute;
}

.animated-button span:nth-child(1) {
  top: 0px;
  left: 0px;
  width: 100%;
  height: 2px;
  background: -webkit-gradient(linear, right top, left top, from(rgba(8, 20, 43, 0)), to(#2662d9));
  background: linear-gradient(to left, rgba(8, 20, 43, 0), #2662d9);
  -webkit-animation: 2s animateTop linear infinite;
          animation: 2s animateTop linear infinite;
}

@-webkit-keyframes animateTop {
  0% {
    -webkit-transform: translateX(100%);
            transform: translateX(100%);
  }
  100% {
    -webkit-transform: translateX(-100%);
            transform: translateX(-100%);
  }
}

@keyframes animateTop {
  0% {
    -webkit-transform: translateX(100%);
            transform: translateX(100%);
  }
  100% {
    -webkit-transform: translateX(-100%);
            transform: translateX(-100%);
  }
}

.animated-button span:nth-child(2) {
  top: 0px;
  right: 0px;
  height: 100%;
  width: 2px;
  background: -webkit-gradient(linear, left bottom, left top, from(rgba(8, 20, 43, 0)), to(#2662d9));
  background: linear-gradient(to top, rgba(8, 20, 43, 0), #2662d9);
  -webkit-animation: 2s animateRight linear -1s infinite;
          animation: 2s animateRight linear -1s infinite;
}

@-webkit-keyframes animateRight {
  0% {
    -webkit-transform: translateY(100%);
            transform: translateY(100%);
  }
  100% {
    -webkit-transform: translateY(-100%);
            transform: translateY(-100%);
  }
}

@keyframes animateRight {
  0% {
    -webkit-transform: translateY(100%);
            transform: translateY(100%);
  }
  100% {
    -webkit-transform: translateY(-100%);
            transform: translateY(-100%);
  }
}

.animated-button span:nth-child(3) {
  bottom: 0px;
  left: 0px;
  width: 100%;
  height: 2px;
  background: -webkit-gradient(linear, left top, right top, from(rgba(8, 20, 43, 0)), to(#2662d9));
  background: linear-gradient(to right, rgba(8, 20, 43, 0), #2662d9);
  -webkit-animation: 2s animateBottom linear infinite;
          animation: 2s animateBottom linear infinite;
}

@-webkit-keyframes animateBottom {
  0% {
    -webkit-transform: translateX(-100%);
            transform: translateX(-100%);
  }
  100% {
    -webkit-transform: translateX(100%);
            transform: translateX(100%);
  }
}

@keyframes animateBottom {
  0% {
    -webkit-transform: translateX(-100%);
            transform: translateX(-100%);
  }
  100% {
    -webkit-transform: translateX(100%);
            transform: translateX(100%);
  }
}

.animated-button span:nth-child(4) {
  top: 0px;
  left: 0px;
  height: 100%;
  width: 2px;
  background: -webkit-gradient(linear, left top, left bottom, from(rgba(8, 20, 43, 0)), to(#2662d9));
  background: linear-gradient(to bottom, rgba(8, 20, 43, 0), #2662d9);
  -webkit-animation: 2s animateLeft linear -1s infinite;
          animation: 2s animateLeft linear -1s infinite;
}

@-webkit-keyframes animateLeft {
  0% {
    -webkit-transform: translateY(-100%);
            transform: translateY(-100%);
  }
  100% {
    -webkit-transform: translateY(100%);
            transform: translateY(100%);
  }
}

@keyframes animateLeft {
  0% {
    -webkit-transform: translateY(-100%);
            transform: translateY(-100%);
  }
  100% {
    -webkit-transform: translateY(100%);
            transform: translateY(100%);
  }
}

.animated-button1 {
  background: linear-gradient(-30deg, #3d0b0b 50%, #2b0808 50%);
  padding: 20px 40px;
  margin: 12px;
  display: inline-block;
  -webkit-transform: translate(0%, 0%);
          transform: translate(0%, 0%);
  overflow: hidden;
  color: #f7d4d4;
  font-size: 20px;
  letter-spacing: 2.5px;
  text-align: center;
  text-transform: uppercase;
  text-decoration: none;
  -webkit-box-shadow: 0 20px 50px rgba(0, 0, 0, 0.5);
          box-shadow: 0 20px 50px rgba(0, 0, 0, 0.5);
}

.animated-button1::before {
  content: '';
  position: absolute;
  top: 0px;
  left: 0px;
  width: 100%;
  height: 100%;
  background-color: #ad8585;
  opacity: 0;
  -webkit-transition: .2s opacity ease-in-out;
  transition: .2s opacity ease-in-out;
}

.animated-button1:hover::before {
  opacity: 0.2;
}

.animated-button1 span {
  position: absolute;
}

.animated-button1 span:nth-child(1) {
  top: 0px;
  left: 0px;
  width: 100%;
  height: 2px;
  background: -webkit-gradient(linear, right top, left top, from(rgba(43, 8, 8, 0)), to(#d92626));
  background: linear-gradient(to left, rgba(43, 8, 8, 0), #d92626);
  -webkit-animation: 2s animateTop linear infinite;
          animation: 2s animateTop linear infinite;
}

@keyframes animateTop {
  0% {
    -webkit-transform: translateX(100%);
            transform: translateX(100%);
  }
  100% {
    -webkit-transform: translateX(-100%);
            transform: translateX(-100%);
  }
}

.animated-button1 span:nth-child(2) {
  top: 0px;
  right: 0px;
  height: 100%;
  width: 2px;
  background: -webkit-gradient(linear, left bottom, left top, from(rgba(43, 8, 8, 0)), to(#d92626));
  background: linear-gradient(to top, rgba(43, 8, 8, 0), #d92626);
  -webkit-animation: 2s animateRight linear -1s infinite;
          animation: 2s animateRight linear -1s infinite;
}

@keyframes animateRight {
  0% {
    -webkit-transform: translateY(100%);
            transform: translateY(100%);
  }
  100% {
    -webkit-transform: translateY(-100%);
            transform: translateY(-100%);
  }
}

.animated-button1 span:nth-child(3) {
  bottom: 0px;
  left: 0px;
  width: 100%;
  height: 2px;
  background: -webkit-gradient(linear, left top, right top, from(rgba(43, 8, 8, 0)), to(#d92626));
  background: linear-gradient(to right, rgba(43, 8, 8, 0), #d92626);
  -webkit-animation: 2s animateBottom linear infinite;
          animation: 2s animateBottom linear infinite;
}

@keyframes animateBottom {
  0% {
    -webkit-transform: translateX(-100%);
            transform: translateX(-100%);
  }
  100% {
    -webkit-transform: translateX(100%);
            transform: translateX(100%);
  }
}

.animated-button1 span:nth-child(4) {
  top: 0px;
  left: 0px;
  height: 100%;
  width: 2px;
  background: -webkit-gradient(linear, left top, left bottom, from(rgba(43, 8, 8, 0)), to(#d92626));
  background: linear-gradient(to bottom, rgba(43, 8, 8, 0), #d92626);
  -webkit-animation: 2s animateLeft linear -1s infinite;
          animation: 2s animateLeft linear -1s infinite;
}

@keyframes animateLeft {
  0% {
    -webkit-transform: translateY(-100%);
            transform: translateY(-100%);
  }
  100% {
    -webkit-transform: translateY(100%);
            transform: translateY(100%);
  }
}

.animated-button2 {
  background: linear-gradient(-30deg, #3d240b 50%, #2b1a08 50%);
  padding: 20px 40px;
  margin: 12px;
  display: inline-block;
  -webkit-transform: translate(0%, 0%);
          transform: translate(0%, 0%);
  overflow: hidden;
  color: #f7e6d4;
  font-size: 20px;
  letter-spacing: 2.5px;
  text-align: center;
  text-transform: uppercase;
  text-decoration: none;
  -webkit-box-shadow: 0 20px 50px rgba(0, 0, 0, 0.5);
          box-shadow: 0 20px 50px rgba(0, 0, 0, 0.5);
}

.animated-button2::before {
  content: '';
  position: absolute;
  top: 0px;
  left: 0px;
  width: 100%;
  height: 100%;
  background-color: #ad9985;
  opacity: 0;
  -webkit-transition: .2s opacity ease-in-out;
  transition: .2s opacity ease-in-out;
}

.animated-button2:hover::before {
  opacity: 0.2;
}

.animated-button2 span {
  position: absolute;
}

.animated-button2 span:nth-child(1) {
  top: 0px;
  left: 0px;
  width: 100%;
  height: 2px;
  background: -webkit-gradient(linear, right top, left top, from(rgba(43, 26, 8, 0)), to(#d98026));
  background: linear-gradient(to left, rgba(43, 26, 8, 0), #d98026);
  -webkit-animation: 2s animateTop linear infinite;
          animation: 2s animateTop linear infinite;
}

@keyframes animateTop {
  0% {
    -webkit-transform: translateX(100%);
            transform: translateX(100%);
  }
  100% {
    -webkit-transform: translateX(-100%);
            transform: translateX(-100%);
  }
}

.animated-button2 span:nth-child(2) {
  top: 0px;
  right: 0px;
  height: 100%;
  width: 2px;
  background: -webkit-gradient(linear, left bottom, left top, from(rgba(43, 26, 8, 0)), to(#d98026));
  background: linear-gradient(to top, rgba(43, 26, 8, 0), #d98026);
  -webkit-animation: 2s animateRight linear -1s infinite;
          animation: 2s animateRight linear -1s infinite;
}

@keyframes animateRight {
  0% {
    -webkit-transform: translateY(100%);
            transform: translateY(100%);
  }
  100% {
    -webkit-transform: translateY(-100%);
            transform: translateY(-100%);
  }
}

.animated-button2 span:nth-child(3) {
  bottom: 0px;
  left: 0px;
  width: 100%;
  height: 2px;
  background: -webkit-gradient(linear, left top, right top, from(rgba(43, 26, 8, 0)), to(#d98026));
  background: linear-gradient(to right, rgba(43, 26, 8, 0), #d98026);
  -webkit-animation: 2s animateBottom linear infinite;
          animation: 2s animateBottom linear infinite;
}

@keyframes animateBottom {
  0% {
    -webkit-transform: translateX(-100%);
            transform: translateX(-100%);
  }
  100% {
    -webkit-transform: translateX(100%);
            transform: translateX(100%);
  }
}

.animated-button2 span:nth-child(4) {
  top: 0px;
  left: 0px;
  height: 100%;
  width: 2px;
  background: -webkit-gradient(linear, left top, left bottom, from(rgba(43, 26, 8, 0)), to(#d98026));
  background: linear-gradient(to bottom, rgba(43, 26, 8, 0), #d98026);
  -webkit-animation: 2s animateLeft linear -1s infinite;
          animation: 2s animateLeft linear -1s infinite;
}

@keyframes animateLeft {
  0% {
    -webkit-transform: translateY(-100%);
            transform: translateY(-100%);
  }
  100% {
    -webkit-transform: translateY(100%);
            transform: translateY(100%);
  }
}

.animated-button3 {
  background: linear-gradient(-30deg, #3d3d0b 50%, #2b2b08 50%);
  padding: 20px 40px;
  margin: 12px;
  display: inline-block;
  -webkit-transform: translate(0%, 0%);
          transform: translate(0%, 0%);
  overflow: hidden;
  color: #f7f7d4;
  font-size: 20px;
  letter-spacing: 2.5px;
  text-align: center;
  text-transform: uppercase;
  text-decoration: none;
  -webkit-box-shadow: 0 20px 50px rgba(0, 0, 0, 0.5);
          box-shadow: 0 20px 50px rgba(0, 0, 0, 0.5);
}

.animated-button3::before {
  content: '';
  position: absolute;
  top: 0px;
  left: 0px;
  width: 100%;
  height: 100%;
  background-color: #adad85;
  opacity: 0;
  -webkit-transition: .2s opacity ease-in-out;
  transition: .2s opacity ease-in-out;
}

.animated-button3:hover::before {
  opacity: 0.2;
}

.animated-button3 span {
  position: absolute;
}

.animated-button3 span:nth-child(1) {
  top: 0px;
  left: 0px;
  width: 100%;
  height: 2px;
  background: -webkit-gradient(linear, right top, left top, from(rgba(43, 43, 8, 0)), to(#d9d926));
  background: linear-gradient(to left, rgba(43, 43, 8, 0), #d9d926);
  -webkit-animation: 2s animateTop linear infinite;
          animation: 2s animateTop linear infinite;
}

@keyframes animateTop {
  0% {
    -webkit-transform: translateX(100%);
            transform: translateX(100%);
  }
  100% {
    -webkit-transform: translateX(-100%);
            transform: translateX(-100%);
  }
}

.animated-button3 span:nth-child(2) {
  top: 0px;
  right: 0px;
  height: 100%;
  width: 2px;
  background: -webkit-gradient(linear, left bottom, left top, from(rgba(43, 43, 8, 0)), to(#d9d926));
  background: linear-gradient(to top, rgba(43, 43, 8, 0), #d9d926);
  -webkit-animation: 2s animateRight linear -1s infinite;
          animation: 2s animateRight linear -1s infinite;
}

@keyframes animateRight {
  0% {
    -webkit-transform: translateY(100%);
            transform: translateY(100%);
  }
  100% {
    -webkit-transform: translateY(-100%);
            transform: translateY(-100%);
  }
}

.animated-button3 span:nth-child(3) {
  bottom: 0px;
  left: 0px;
  width: 100%;
  height: 2px;
  background: -webkit-gradient(linear, left top, right top, from(rgba(43, 43, 8, 0)), to(#d9d926));
  background: linear-gradient(to right, rgba(43, 43, 8, 0), #d9d926);
  -webkit-animation: 2s animateBottom linear infinite;
          animation: 2s animateBottom linear infinite;
}

@keyframes animateBottom {
  0% {
    -webkit-transform: translateX(-100%);
            transform: translateX(-100%);
  }
  100% {
    -webkit-transform: translateX(100%);
            transform: translateX(100%);
  }
}

.animated-button3 span:nth-child(4) {
  top: 0px;
  left: 0px;
  height: 100%;
  width: 2px;
  background: -webkit-gradient(linear, left top, left bottom, from(rgba(43, 43, 8, 0)), to(#d9d926));
  background: linear-gradient(to bottom, rgba(43, 43, 8, 0), #d9d926);
  -webkit-animation: 2s animateLeft linear -1s infinite;
          animation: 2s animateLeft linear -1s infinite;
}

@keyframes animateLeft {
  0% {
    -webkit-transform: translateY(-100%);
            transform: translateY(-100%);
  }
  100% {
    -webkit-transform: translateY(100%);
            transform: translateY(100%);
  }
}

.animated-button4 {
  background: linear-gradient(-30deg, #243d0b 50%, #1a2b08 50%);
  padding: 20px 40px;
  margin: 12px;
  display: inline-block;
  -webkit-transform: translate(0%, 0%);
          transform: translate(0%, 0%);
  overflow: hidden;
  color: #e6f7d4;
  font-size: 20px;
  letter-spacing: 2.5px;
  text-align: center;
  text-transform: uppercase;
  text-decoration: none;
  -webkit-box-shadow: 0 20px 50px rgba(0, 0, 0, 0.5);
          box-shadow: 0 20px 50px rgba(0, 0, 0, 0.5);
}

.animated-button4::before {
  content: '';
  position: absolute;
  top: 0px;
  left: 0px;
  width: 100%;
  height: 100%;
  background-color: #99ad85;
  opacity: 0;
  -webkit-transition: .2s opacity ease-in-out;
  transition: .2s opacity ease-in-out;
}

.animated-button4:hover::before {
  opacity: 0.2;
}

.animated-button4 span {
  position: absolute;
}

.animated-button4 span:nth-child(1) {
  top: 0px;
  left: 0px;
  width: 100%;
  height: 2px;
  background: -webkit-gradient(linear, right top, left top, from(rgba(26, 43, 8, 0)), to(#80d926));
  background: linear-gradient(to left, rgba(26, 43, 8, 0), #80d926);
  -webkit-animation: 2s animateTop linear infinite;
          animation: 2s animateTop linear infinite;
}

@keyframes animateTop {
  0% {
    -webkit-transform: translateX(100%);
            transform: translateX(100%);
  }
  100% {
    -webkit-transform: translateX(-100%);
            transform: translateX(-100%);
  }
}

.animated-button4 span:nth-child(2) {
  top: 0px;
  right: 0px;
  height: 100%;
  width: 2px;
  background: -webkit-gradient(linear, left bottom, left top, from(rgba(26, 43, 8, 0)), to(#80d926));
  background: linear-gradient(to top, rgba(26, 43, 8, 0), #80d926);
  -webkit-animation: 2s animateRight linear -1s infinite;
          animation: 2s animateRight linear -1s infinite;
}

@keyframes animateRight {
  0% {
    -webkit-transform: translateY(100%);
            transform: translateY(100%);
  }
  100% {
    -webkit-transform: translateY(-100%);
            transform: translateY(-100%);
  }
}

.animated-button4 span:nth-child(3) {
  bottom: 0px;
  left: 0px;
  width: 100%;
  height: 2px;
  background: -webkit-gradient(linear, left top, right top, from(rgba(26, 43, 8, 0)), to(#80d926));
  background: linear-gradient(to right, rgba(26, 43, 8, 0), #80d926);
  -webkit-animation: 2s animateBottom linear infinite;
          animation: 2s animateBottom linear infinite;
}

@keyframes animateBottom {
  0% {
    -webkit-transform: translateX(-100%);
            transform: translateX(-100%);
  }
  100% {
    -webkit-transform: translateX(100%);
            transform: translateX(100%);
  }
}

.animated-button4 span:nth-child(4) {
  top: 0px;
  left: 0px;
  height: 100%;
  width: 2px;
  background: -webkit-gradient(linear, left top, left bottom, from(rgba(26, 43, 8, 0)), to(#80d926));
  background: linear-gradient(to bottom, rgba(26, 43, 8, 0), #80d926);
  -webkit-animation: 2s animateLeft linear -1s infinite;
          animation: 2s animateLeft linear -1s infinite;
}

@keyframes animateLeft {
  0% {
    -webkit-transform: translateY(-100%);
            transform: translateY(-100%);
  }
  100% {
    -webkit-transform: translateY(100%);
            transform: translateY(100%);
  }
}

.animated-button5 {
  background: linear-gradient(-30deg, #0b3d0b 50%, #082b08 50%);
  padding: 20px 40px;
  margin: 12px;
  display: inline-block;
  -webkit-transform: translate(0%, 0%);
          transform: translate(0%, 0%);
  overflow: hidden;
  color: #d4f7d4;
  font-size: 20px;
  letter-spacing: 2.5px;
  text-align: center;
  text-transform: uppercase;
  text-decoration: none;
  -webkit-box-shadow: 0 20px 50px rgba(0, 0, 0, 0.5);
          box-shadow: 0 20px 50px rgba(0, 0, 0, 0.5);
}

.animated-button5::before {
  content: '';
  position: absolute;
  top: 0px;
  left: 0px;
  width: 100%;
  height: 100%;
  background-color: #85ad85;
  opacity: 0;
  -webkit-transition: .2s opacity ease-in-out;
  transition: .2s opacity ease-in-out;
}

.animated-button5:hover::before {
  opacity: 0.2;
}

.animated-button5 span {
  position: absolute;
}

.animated-button5 span:nth-child(1) {
  top: 0px;
  left: 0px;
  width: 100%;
  height: 2px;
  background: -webkit-gradient(linear, right top, left top, from(rgba(8, 43, 8, 0)), to(#26d926));
  background: linear-gradient(to left, rgba(8, 43, 8, 0), #26d926);
  -webkit-animation: 2s animateTop linear infinite;
          animation: 2s animateTop linear infinite;
}

@keyframes animateTop {
  0% {
    -webkit-transform: translateX(100%);
            transform: translateX(100%);
  }
  100% {
    -webkit-transform: translateX(-100%);
            transform: translateX(-100%);
  }
}

.animated-button5 span:nth-child(2) {
  top: 0px;
  right: 0px;
  height: 100%;
  width: 2px;
  background: -webkit-gradient(linear, left bottom, left top, from(rgba(8, 43, 8, 0)), to(#26d926));
  background: linear-gradient(to top, rgba(8, 43, 8, 0), #26d926);
  -webkit-animation: 2s animateRight linear -1s infinite;
          animation: 2s animateRight linear -1s infinite;
}

@keyframes animateRight {
  0% {
    -webkit-transform: translateY(100%);
            transform: translateY(100%);
  }
  100% {
    -webkit-transform: translateY(-100%);
            transform: translateY(-100%);
  }
}

.animated-button5 span:nth-child(3) {
  bottom: 0px;
  left: 0px;
  width: 100%;
  height: 2px;
  background: -webkit-gradient(linear, left top, right top, from(rgba(8, 43, 8, 0)), to(#26d926));
  background: linear-gradient(to right, rgba(8, 43, 8, 0), #26d926);
  -webkit-animation: 2s animateBottom linear infinite;
          animation: 2s animateBottom linear infinite;
}

@keyframes animateBottom {
  0% {
    -webkit-transform: translateX(-100%);
            transform: translateX(-100%);
  }
  100% {
    -webkit-transform: translateX(100%);
            transform: translateX(100%);
  }
}

.animated-button5 span:nth-child(4) {
  top: 0px;
  left: 0px;
  height: 100%;
  width: 2px;
  background: -webkit-gradient(linear, left top, left bottom, from(rgba(8, 43, 8, 0)), to(#26d926));
  background: linear-gradient(to bottom, rgba(8, 43, 8, 0), #26d926);
  -webkit-animation: 2s animateLeft linear -1s infinite;
          animation: 2s animateLeft linear -1s infinite;
}

@keyframes animateLeft {
  0% {
    -webkit-transform: translateY(-100%);
            transform: translateY(-100%);
  }
  100% {
    -webkit-transform: translateY(100%);
            transform: translateY(100%);
  }
}

.animated-button6 {
  background: linear-gradient(-30deg, #0b3d24 50%, #082b1a 50%);
  padding: 20px 40px;
  margin: 12px;
  display: inline-block;
  -webkit-transform: translate(0%, 0%);
          transform: translate(0%, 0%);
  overflow: hidden;
  color: #d4f7e6;
  font-size: 20px;
  letter-spacing: 2.5px;
  text-align: center;
  text-transform: uppercase;
  text-decoration: none;
  -webkit-box-shadow: 0 20px 50px rgba(0, 0, 0, 0.5);
          box-shadow: 0 20px 50px rgba(0, 0, 0, 0.5);
}

.animated-button6::before {
  content: '';
  position: absolute;
  top: 0px;
  left: 0px;
  width: 100%;
  height: 100%;
  background-color: #85ad99;
  opacity: 0;
  -webkit-transition: .2s opacity ease-in-out;
  transition: .2s opacity ease-in-out;
}

.animated-button6:hover::before {
  opacity: 0.2;
}

.animated-button6 span {
  position: absolute;
}

.animated-button6 span:nth-child(1) {
  top: 0px;
  left: 0px;
  width: 100%;
  height: 2px;
  background: -webkit-gradient(linear, right top, left top, from(rgba(8, 43, 26, 0)), to(#26d980));
  background: linear-gradient(to left, rgba(8, 43, 26, 0), #26d980);
  -webkit-animation: 2s animateTop linear infinite;
          animation: 2s animateTop linear infinite;
}

@keyframes animateTop {
  0% {
    -webkit-transform: translateX(100%);
            transform: translateX(100%);
  }
  100% {
    -webkit-transform: translateX(-100%);
            transform: translateX(-100%);
  }
}

.animated-button6 span:nth-child(2) {
  top: 0px;
  right: 0px;
  height: 100%;
  width: 2px;
  background: -webkit-gradient(linear, left bottom, left top, from(rgba(8, 43, 26, 0)), to(#26d980));
  background: linear-gradient(to top, rgba(8, 43, 26, 0), #26d980);
  -webkit-animation: 2s animateRight linear -1s infinite;
          animation: 2s animateRight linear -1s infinite;
}

@keyframes animateRight {
  0% {
    -webkit-transform: translateY(100%);
            transform: translateY(100%);
  }
  100% {
    -webkit-transform: translateY(-100%);
            transform: translateY(-100%);
  }
}

.animated-button6 span:nth-child(3) {
  bottom: 0px;
  left: 0px;
  width: 100%;
  height: 2px;
  background: -webkit-gradient(linear, left top, right top, from(rgba(8, 43, 26, 0)), to(#26d980));
  background: linear-gradient(to right, rgba(8, 43, 26, 0), #26d980);
  -webkit-animation: 2s animateBottom linear infinite;
          animation: 2s animateBottom linear infinite;
}

@keyframes animateBottom {
  0% {
    -webkit-transform: translateX(-100%);
            transform: translateX(-100%);
  }
  100% {
    -webkit-transform: translateX(100%);
            transform: translateX(100%);
  }
}

.animated-button6 span:nth-child(4) {
  top: 0px;
  left: 0px;
  height: 100%;
  width: 2px;
  background: -webkit-gradient(linear, left top, left bottom, from(rgba(8, 43, 26, 0)), to(#26d980));
  background: linear-gradient(to bottom, rgba(8, 43, 26, 0), #26d980);
  -webkit-animation: 2s animateLeft linear -1s infinite;
          animation: 2s animateLeft linear -1s infinite;
}

@keyframes animateLeft {
  0% {
    -webkit-transform: translateY(-100%);
            transform: translateY(-100%);
  }
  100% {
    -webkit-transform: translateY(100%);
            transform: translateY(100%);
  }
}

.animated-button7 {
  background: linear-gradient(-30deg, #0b3d3d 50%, #082b2b 50%);
  padding: 20px 40px;
  margin: 12px;
  display: inline-block;
  -webkit-transform: translate(0%, 0%);
          transform: translate(0%, 0%);
  overflow: hidden;
  color: #d4f7f7;
  font-size: 20px;
  letter-spacing: 2.5px;
  text-align: center;
  text-transform: uppercase;
  text-decoration: none;
  -webkit-box-shadow: 0 20px 50px rgba(0, 0, 0, 0.5);
          box-shadow: 0 20px 50px rgba(0, 0, 0, 0.5);
}

.animated-button7::before {
  content: '';
  position: absolute;
  top: 0px;
  left: 0px;
  width: 100%;
  height: 100%;
  background-color: #85adad;
  opacity: 0;
  -webkit-transition: .2s opacity ease-in-out;
  transition: .2s opacity ease-in-out;
}

.animated-button7:hover::before {
  opacity: 0.2;
}

.animated-button7 span {
  position: absolute;
}

.animated-button7 span:nth-child(1) {
  top: 0px;
  left: 0px;
  width: 100%;
  height: 2px;
  background: -webkit-gradient(linear, right top, left top, from(rgba(8, 43, 43, 0)), to(#26d9d9));
  background: linear-gradient(to left, rgba(8, 43, 43, 0), #26d9d9);
  -webkit-animation: 2s animateTop linear infinite;
          animation: 2s animateTop linear infinite;
}

@keyframes animateTop {
  0% {
    -webkit-transform: translateX(100%);
            transform: translateX(100%);
  }
  100% {
    -webkit-transform: translateX(-100%);
            transform: translateX(-100%);
  }
}

.animated-button7 span:nth-child(2) {
  top: 0px;
  right: 0px;
  height: 100%;
  width: 2px;
  background: -webkit-gradient(linear, left bottom, left top, from(rgba(8, 43, 43, 0)), to(#26d9d9));
  background: linear-gradient(to top, rgba(8, 43, 43, 0), #26d9d9);
  -webkit-animation: 2s animateRight linear -1s infinite;
          animation: 2s animateRight linear -1s infinite;
}

@keyframes animateRight {
  0% {
    -webkit-transform: translateY(100%);
            transform: translateY(100%);
  }
  100% {
    -webkit-transform: translateY(-100%);
            transform: translateY(-100%);
  }
}

.animated-button7 span:nth-child(3) {
  bottom: 0px;
  left: 0px;
  width: 100%;
  height: 2px;
  background: -webkit-gradient(linear, left top, right top, from(rgba(8, 43, 43, 0)), to(#26d9d9));
  background: linear-gradient(to right, rgba(8, 43, 43, 0), #26d9d9);
  -webkit-animation: 2s animateBottom linear infinite;
          animation: 2s animateBottom linear infinite;
}

@keyframes animateBottom {
  0% {
    -webkit-transform: translateX(-100%);
            transform: translateX(-100%);
  }
  100% {
    -webkit-transform: translateX(100%);
            transform: translateX(100%);
  }
}

.animated-button7 span:nth-child(4) {
  top: 0px;
  left: 0px;
  height: 100%;
  width: 2px;
  background: -webkit-gradient(linear, left top, left bottom, from(rgba(8, 43, 43, 0)), to(#26d9d9));
  background: linear-gradient(to bottom, rgba(8, 43, 43, 0), #26d9d9);
  -webkit-animation: 2s animateLeft linear -1s infinite;
          animation: 2s animateLeft linear -1s infinite;
}

@keyframes animateLeft {
  0% {
    -webkit-transform: translateY(-100%);
            transform: translateY(-100%);
  }
  100% {
    -webkit-transform: translateY(100%);
            transform: translateY(100%);
  }
}

.animated-button8 {
  background: linear-gradient(-30deg, #0b243d 50%, #081a2b 50%);
  padding: 20px 40px;
  margin: 12px;
  display: inline-block;
  -webkit-transform: translate(0%, 0%);
          transform: translate(0%, 0%);
  overflow: hidden;
  color: #d4e6f7;
  font-size: 20px;
  letter-spacing: 2.5px;
  text-align: center;
  text-transform: uppercase;
  text-decoration: none;
  -webkit-box-shadow: 0 20px 50px rgba(0, 0, 0, 0.5);
          box-shadow: 0 20px 50px rgba(0, 0, 0, 0.5);
}

.animated-button8::before {
  content: '';
  position: absolute;
  top: 0px;
  left: 0px;
  width: 100%;
  height: 100%;
  background-color: #8599ad;
  opacity: 0;
  -webkit-transition: .2s opacity ease-in-out;
  transition: .2s opacity ease-in-out;
}

.animated-button8:hover::before {
  opacity: 0.2;
}

.animated-button8 span {
  position: absolute;
}

.animated-button8 span:nth-child(1) {
  top: 0px;
  left: 0px;
  width: 100%;
  height: 2px;
  background: -webkit-gradient(linear, right top, left top, from(rgba(8, 26, 43, 0)), to(#2680d9));
  background: linear-gradient(to left, rgba(8, 26, 43, 0), #2680d9);
  -webkit-animation: 2s animateTop linear infinite;
          animation: 2s animateTop linear infinite;
}

@keyframes animateTop {
  0% {
    -webkit-transform: translateX(100%);
            transform: translateX(100%);
  }
  100% {
    -webkit-transform: translateX(-100%);
            transform: translateX(-100%);
  }
}

.animated-button8 span:nth-child(2) {
  top: 0px;
  right: 0px;
  height: 100%;
  width: 2px;
  background: -webkit-gradient(linear, left bottom, left top, from(rgba(8, 26, 43, 0)), to(#2680d9));
  background: linear-gradient(to top, rgba(8, 26, 43, 0), #2680d9);
  -webkit-animation: 2s animateRight linear -1s infinite;
          animation: 2s animateRight linear -1s infinite;
}

@keyframes animateRight {
  0% {
    -webkit-transform: translateY(100%);
            transform: translateY(100%);
  }
  100% {
    -webkit-transform: translateY(-100%);
            transform: translateY(-100%);
  }
}

.animated-button8 span:nth-child(3) {
  bottom: 0px;
  left: 0px;
  width: 100%;
  height: 2px;
  background: -webkit-gradient(linear, left top, right top, from(rgba(8, 26, 43, 0)), to(#2680d9));
  background: linear-gradient(to right, rgba(8, 26, 43, 0), #2680d9);
  -webkit-animation: 2s animateBottom linear infinite;
          animation: 2s animateBottom linear infinite;
}

@keyframes animateBottom {
  0% {
    -webkit-transform: translateX(-100%);
            transform: translateX(-100%);
  }
  100% {
    -webkit-transform: translateX(100%);
            transform: translateX(100%);
  }
}

.animated-button8 span:nth-child(4) {
  top: 0px;
  left: 0px;
  height: 100%;
  width: 2px;
  background: -webkit-gradient(linear, left top, left bottom, from(rgba(8, 26, 43, 0)), to(#2680d9));
  background: linear-gradient(to bottom, rgba(8, 26, 43, 0), #2680d9);
  -webkit-animation: 2s animateLeft linear -1s infinite;
          animation: 2s animateLeft linear -1s infinite;
}

@keyframes animateLeft {
  0% {
    -webkit-transform: translateY(-100%);
            transform: translateY(-100%);
  }
  100% {
    -webkit-transform: translateY(100%);
            transform: translateY(100%);
  }
}

.animated-button9 {
  background: linear-gradient(-30deg, #0b0b3d 50%, #08082b 50%);
  padding: 20px 40px;
  margin: 12px;
  display: inline-block;
  -webkit-transform: translate(0%, 0%);
          transform: translate(0%, 0%);
  overflow: hidden;
  color: #d4d4f7;
  font-size: 20px;
  letter-spacing: 2.5px;
  text-align: center;
  text-transform: uppercase;
  text-decoration: none;
  -webkit-box-shadow: 0 20px 50px rgba(0, 0, 0, 0.5);
          box-shadow: 0 20px 50px rgba(0, 0, 0, 0.5);
}

.animated-button9::before {
  content: '';
  position: absolute;
  top: 0px;
  left: 0px;
  width: 100%;
  height: 100%;
  background-color: #8585ad;
  opacity: 0;
  -webkit-transition: .2s opacity ease-in-out;
  transition: .2s opacity ease-in-out;
}

.animated-button9:hover::before {
  opacity: 0.2;
}

.animated-button9 span {
  position: absolute;
}

.animated-button9 span:nth-child(1) {
  top: 0px;
  left: 0px;
  width: 100%;
  height: 2px;
  background: -webkit-gradient(linear, right top, left top, from(rgba(8, 8, 43, 0)), to(#2626d9));
  background: linear-gradient(to left, rgba(8, 8, 43, 0), #2626d9);
  -webkit-animation: 2s animateTop linear infinite;
          animation: 2s animateTop linear infinite;
}

@keyframes animateTop {
  0% {
    -webkit-transform: translateX(100%);
            transform: translateX(100%);
  }
  100% {
    -webkit-transform: translateX(-100%);
            transform: translateX(-100%);
  }
}

.animated-button9 span:nth-child(2) {
  top: 0px;
  right: 0px;
  height: 100%;
  width: 2px;
  background: -webkit-gradient(linear, left bottom, left top, from(rgba(8, 8, 43, 0)), to(#2626d9));
  background: linear-gradient(to top, rgba(8, 8, 43, 0), #2626d9);
  -webkit-animation: 2s animateRight linear -1s infinite;
          animation: 2s animateRight linear -1s infinite;
}

@keyframes animateRight {
  0% {
    -webkit-transform: translateY(100%);
            transform: translateY(100%);
  }
  100% {
    -webkit-transform: translateY(-100%);
            transform: translateY(-100%);
  }
}

.animated-button9 span:nth-child(3) {
  bottom: 0px;
  left: 0px;
  width: 100%;
  height: 2px;
  background: -webkit-gradient(linear, left top, right top, from(rgba(8, 8, 43, 0)), to(#2626d9));
  background: linear-gradient(to right, rgba(8, 8, 43, 0), #2626d9);
  -webkit-animation: 2s animateBottom linear infinite;
          animation: 2s animateBottom linear infinite;
}

@keyframes animateBottom {
  0% {
    -webkit-transform: translateX(-100%);
            transform: translateX(-100%);
  }
  100% {
    -webkit-transform: translateX(100%);
            transform: translateX(100%);
  }
}

.animated-button9 span:nth-child(4) {
  top: 0px;
  left: 0px;
  height: 100%;
  width: 2px;
  background: -webkit-gradient(linear, left top, left bottom, from(rgba(8, 8, 43, 0)), to(#2626d9));
  background: linear-gradient(to bottom, rgba(8, 8, 43, 0), #2626d9);
  -webkit-animation: 2s animateLeft linear -1s infinite;
          animation: 2s animateLeft linear -1s infinite;
}

@keyframes animateLeft {
  0% {
    -webkit-transform: translateY(-100%);
            transform: translateY(-100%);
  }
  100% {
    -webkit-transform: translateY(100%);
            transform: translateY(100%);
  }
}

.animated-button10 {
  background: linear-gradient(-30deg, #240b3d 50%, #1a082b 50%);
  padding: 20px 40px;
  margin: 12px;
  display: inline-block;
  -webkit-transform: translate(0%, 0%);
          transform: translate(0%, 0%);
  overflow: hidden;
  color: #e6d4f7;
  font-size: 20px;
  letter-spacing: 2.5px;
  text-align: center;
  text-transform: uppercase;
  text-decoration: none;
  -webkit-box-shadow: 0 20px 50px rgba(0, 0, 0, 0.5);
          box-shadow: 0 20px 50px rgba(0, 0, 0, 0.5);
}

.animated-button10::before {
  content: '';
  position: absolute;
  top: 0px;
  left: 0px;
  width: 100%;
  height: 100%;
  background-color: #9985ad;
  opacity: 0;
  -webkit-transition: .2s opacity ease-in-out;
  transition: .2s opacity ease-in-out;
}

.animated-button10:hover::before {
  opacity: 0.2;
}

.animated-button10 span {
  position: absolute;
}

.animated-button10 span:nth-child(1) {
  top: 0px;
  left: 0px;
  width: 100%;
  height: 2px;
  background: -webkit-gradient(linear, right top, left top, from(rgba(26, 8, 43, 0)), to(#8026d9));
  background: linear-gradient(to left, rgba(26, 8, 43, 0), #8026d9);
  -webkit-animation: 2s animateTop linear infinite;
          animation: 2s animateTop linear infinite;
}

@keyframes animateTop {
  0% {
    -webkit-transform: translateX(100%);
            transform: translateX(100%);
  }
  100% {
    -webkit-transform: translateX(-100%);
            transform: translateX(-100%);
  }
}

.animated-button10 span:nth-child(2) {
  top: 0px;
  right: 0px;
  height: 100%;
  width: 2px;
  background: -webkit-gradient(linear, left bottom, left top, from(rgba(26, 8, 43, 0)), to(#8026d9));
  background: linear-gradient(to top, rgba(26, 8, 43, 0), #8026d9);
  -webkit-animation: 2s animateRight linear -1s infinite;
          animation: 2s animateRight linear -1s infinite;
}

@keyframes animateRight {
  0% {
    -webkit-transform: translateY(100%);
            transform: translateY(100%);
  }
  100% {
    -webkit-transform: translateY(-100%);
            transform: translateY(-100%);
  }
}

.animated-button10 span:nth-child(3) {
  bottom: 0px;
  left: 0px;
  width: 100%;
  height: 2px;
  background: -webkit-gradient(linear, left top, right top, from(rgba(26, 8, 43, 0)), to(#8026d9));
  background: linear-gradient(to right, rgba(26, 8, 43, 0), #8026d9);
  -webkit-animation: 2s animateBottom linear infinite;
          animation: 2s animateBottom linear infinite;
}

@keyframes animateBottom {
  0% {
    -webkit-transform: translateX(-100%);
            transform: translateX(-100%);
  }
  100% {
    -webkit-transform: translateX(100%);
            transform: translateX(100%);
  }
}

.animated-button10 span:nth-child(4) {
  top: 0px;
  left: 0px;
  height: 100%;
  width: 2px;
  background: -webkit-gradient(linear, left top, left bottom, from(rgba(26, 8, 43, 0)), to(#8026d9));
  background: linear-gradient(to bottom, rgba(26, 8, 43, 0), #8026d9);
  -webkit-animation: 2s animateLeft linear -1s infinite;
          animation: 2s animateLeft linear -1s infinite;
}

@keyframes animateLeft {
  0% {
    -webkit-transform: translateY(-100%);
            transform: translateY(-100%);
  }
  100% {
    -webkit-transform: translateY(100%);
            transform: translateY(100%);
  }
}

.animated-button11 {
  background: linear-gradient(-30deg, #3d0b3d 50%, #2b082b 50%);
  padding: 20px 40px;
  margin: 12px;
  display: inline-block;
  -webkit-transform: translate(0%, 0%);
          transform: translate(0%, 0%);
  overflow: hidden;
  color: #f7d4f7;
  font-size: 20px;
  letter-spacing: 2.5px;
  text-align: center;
  text-transform: uppercase;
  text-decoration: none;
  -webkit-box-shadow: 0 20px 50px rgba(0, 0, 0, 0.5);
          box-shadow: 0 20px 50px rgba(0, 0, 0, 0.5);
}

.animated-button11::before {
  content: '';
  position: absolute;
  top: 0px;
  left: 0px;
  width: 100%;
  height: 100%;
  background-color: #ad85ad;
  opacity: 0;
  -webkit-transition: .2s opacity ease-in-out;
  transition: .2s opacity ease-in-out;
}

.animated-button11:hover::before {
  opacity: 0.2;
}

.animated-button11 span {
  position: absolute;
}

.animated-button11 span:nth-child(1) {
  top: 0px;
  left: 0px;
  width: 100%;
  height: 2px;
  background: -webkit-gradient(linear, right top, left top, from(rgba(43, 8, 43, 0)), to(#d926d9));
  background: linear-gradient(to left, rgba(43, 8, 43, 0), #d926d9);
  -webkit-animation: 2s animateTop linear infinite;
          animation: 2s animateTop linear infinite;
}

@keyframes animateTop {
  0% {
    -webkit-transform: translateX(100%);
            transform: translateX(100%);
  }
  100% {
    -webkit-transform: translateX(-100%);
            transform: translateX(-100%);
  }
}

.animated-button11 span:nth-child(2) {
  top: 0px;
  right: 0px;
  height: 100%;
  width: 2px;
  background: -webkit-gradient(linear, left bottom, left top, from(rgba(43, 8, 43, 0)), to(#d926d9));
  background: linear-gradient(to top, rgba(43, 8, 43, 0), #d926d9);
  -webkit-animation: 2s animateRight linear -1s infinite;
          animation: 2s animateRight linear -1s infinite;
}

@keyframes animateRight {
  0% {
    -webkit-transform: translateY(100%);
            transform: translateY(100%);
  }
  100% {
    -webkit-transform: translateY(-100%);
            transform: translateY(-100%);
  }
}

.animated-button11 span:nth-child(3) {
  bottom: 0px;
  left: 0px;
  width: 100%;
  height: 2px;
  background: -webkit-gradient(linear, left top, right top, from(rgba(43, 8, 43, 0)), to(#d926d9));
  background: linear-gradient(to right, rgba(43, 8, 43, 0), #d926d9);
  -webkit-animation: 2s animateBottom linear infinite;
          animation: 2s animateBottom linear infinite;
}

@keyframes animateBottom {
  0% {
    -webkit-transform: translateX(-100%);
            transform: translateX(-100%);
  }
  100% {
    -webkit-transform: translateX(100%);
            transform: translateX(100%);
  }
}

.animated-button11 span:nth-child(4) {
  top: 0px;
  left: 0px;
  height: 100%;
  width: 2px;
  background: -webkit-gradient(linear, left top, left bottom, from(rgba(43, 8, 43, 0)), to(#d926d9));
  background: linear-gradient(to bottom, rgba(43, 8, 43, 0), #d926d9);
  -webkit-animation: 2s animateLeft linear -1s infinite;
          animation: 2s animateLeft linear -1s infinite;
}

@keyframes animateLeft {
  0% {
    -webkit-transform: translateY(-100%);
            transform: translateY(-100%);
  }
  100% {
    -webkit-transform: translateY(100%);
            transform: translateY(100%);
  }
}

.intro-y.active{
  background: linear-gradient(-30deg, #ff5b2d 50%, #148b83 50%) !important;
}

.animated-button12 {
  background: linear-gradient(-30deg, #ff5b2d73 50%, #148b8380 50%);
  padding: 20px 40px;
  margin: 12px;
  display: inline-block;
  -webkit-transform: translate(0%, 0%);
          transform: translate(0%, 0%);
  overflow: hidden;
  color: black;
  font-size: 20px;
  letter-spacing: 2.5px;
  text-align: center;
  text-transform: uppercase;
  text-decoration: none;
  -webkit-box-shadow: 0 20px 50px rgba(0, 0, 0, 0.5);
          box-shadow: 0 20px 50px rgba(0, 0, 0, 0.5);
}

.animated-button12::before {
  content: '';
  position: absolute;
  top: 0px;
  left: 0px;
  width: 100%;
  height: 100%;
  background-color: #ad8599;
  opacity: 0;
  -webkit-transition: .2s opacity ease-in-out;
  transition: .2s opacity ease-in-out;
}

.animated-button12:hover::before {
  opacity: 0.2;
}

.animated-button12 span {
  position: absolute;
}

.animated-button12 span:nth-child(1) {
  top: 0px;
  left: 0px;
  width: 100%;
  height: 2px;
  background: -webkit-gradient(linear, right top, left top, from(rgba(43, 8, 26, 0)), to(#000000));
  background: linear-gradient(to left, rgba(43, 8, 26, 0), #000000);
  -webkit-animation: 2s animateTop linear infinite;
          animation: 2s animateTop linear infinite;
}

@keyframes animateTop {
  0% {
    -webkit-transform: translateX(100%);
            transform: translateX(100%);
  }
  100% {
    -webkit-transform: translateX(-100%);
            transform: translateX(-100%);
  }
}

.animated-button12 span:nth-child(2) {
  top: 0px;
  right: 0px;
  height: 100%;
  width: 2px;
  background: -webkit-gradient(linear, left bottom, left top, from(rgba(43, 8, 26, 0)), to(#000000));
  background: linear-gradient(to top, rgba(43, 8, 26, 0), #000000);
  -webkit-animation: 2s animateRight linear -1s infinite;
          animation: 2s animateRight linear -1s infinite;
}

@keyframes animateRight {
  0% {
    -webkit-transform: translateY(100%);
            transform: translateY(100%);
  }
  100% {
    -webkit-transform: translateY(-100%);
            transform: translateY(-100%);
  }
}

.animated-button12 span:nth-child(3) {
  bottom: 0px;
  left: 0px;
  width: 100%;
  height: 2px;
  background: -webkit-gradient(linear, left top, right top, from(rgba(43, 8, 26, 0)), to(#000000));
  background: linear-gradient(to right, rgba(43, 8, 26, 0), #000000);
  -webkit-animation: 2s animateBottom linear infinite;
          animation: 2s animateBottom linear infinite;
}

@keyframes animateBottom {
  0% {
    -webkit-transform: translateX(-100%);
            transform: translateX(-100%);
  }
  100% {
    -webkit-transform: translateX(100%);
            transform: translateX(100%);
  }
}

.animated-button12 span:nth-child(4) {
  top: 0px;
  left: 0px;
  height: 100%;
  width: 2px;
  background: -webkit-gradient(linear, left top, left bottom, from(rgba(43, 8, 26, 0)), to(#000000));
  background: linear-gradient(to bottom, rgba(43, 8, 26, 0), #000000);
  -webkit-animation: 2s animateLeft linear -1s infinite;
          animation: 2s animateLeft linear -1s infinite;
}

@keyframes animateLeft {
  0% {
    -webkit-transform: translateY(-100%);
            transform: translateY(-100%);
  }
  100% {
    -webkit-transform: translateY(100%);
            transform: translateY(100%);
  }
}

</style>

export default {
    list: {
        title: "status_history",
        icone: "history",
        class: "flex-1 mt-1 px-3 items-center",
        name: 'statusHistories',
        options: {
            defaultPageSize: 5,
            actions: {
                isActive: false,
                method: true,
                name: "Actions",
                buttons: [{
                    text: "Csv",
                    icon: "file_download",
                    color: 'primary',
                    class:''
                },],
            }
        },
        columns: [
            {
                name: "order_id",
                class: "",
                elements: [{
                    items: [{
                        class: "",
                        name: "order_id",
                        type: "text",
                    }],
                }, ]
            },
            {
                name: "date_action",
                class: "",
                elements: [{
                    items: [{
                        class: "",
                        name: "dateDebut",
                        date: true,
                        type: "text",
                    }],
                }, ]
            },
            {
                name: "time_spent",
                class: "",
                elements: [{
                    items: [{
                        class: "",
                        name: "timeConsumed",
                        type: "text",
                    }],
                }, ]
            },
            {
                name: "previous_status",
                class: "",
                elements: [{
                    items: [{
                        class: "border capitalize rounded-full text-gray-600 h-8 text-xs px-8 bg-white hover:border-gray-500 focus:outline-none appearance-none",
                        name: "previousStatus",
                        data: true,
                        champs: {
                            name: "name",
                            color: "color",
                        },
                    }],
                }, ]
            },
            {
                name: "change_to",
                class: "",
                elements: [{
                    items: [{
                        class: "border capitalize rounded-full text-gray-600 h-8 text-xs px-8 bg-white hover:border-gray-500 focus:outline-none appearance-none",
                        name: "status",
                        data: true,
                        champs: {
                            name: "name",
                            color: "color",
                        },
                    }],
                }, ]
            },
            {
                name: "comment",
                class: "",
                elements: [{
                    items: [{
                        class: "",
                        name: "comment",
                        type: "text",
                    }],
                }, ]
            },
        ],
        actions: {
            name: "actions",
            width: "10px",
            class: "rounded-full flex justify-center items-center focus:outline-none w-8 h-8 mr-3 mt-1",
            elements: [{
                icone: "delete",
                color: "danger",
                role: true,
                key: 'delete',
                tooltip: "Delete",
            }],
        },
        filters: {
            name: 'products',
            elements: {
                search: {
                    options: {
                        icon: "search",
                        label: "search",
                        class: "",
                        hint: "search_product"
                    },
                    value: null,
                    type: "search",
                    validate: {
                        trim: true,
                        required: false,
                        minlength: 0,
                    }
                },
                champs: [],
            },
        },
    },
}
<template>
  <div>
    <div class="flex items-center justify-center px-5 py-5">
      <div
        class="w-full mx-auto rounded-xl bg-white shadow-sm p-10 text-gray-800 relative overflow-hidden"
      >
        <div class="relative mt-1">
          <div class="flex space-x-2">
            <!-- <select
              id="teleconsultant"
              placeholder="Select Teleconsultant"
              class="border p-2 w-1/2 focus:outline-none border-blue-500 hover:border-green-600  rounded-md"
            >
              <option selected disabled :value="null">zone name</option>
              <option>soukaina eddib</option>
            </select> -->
            <select
              id="type"
              name="date"
              autocomplete="type"
              class="w-1/3 pl-3 pr-10 py-2 border-2 border-gray-200 rounded-xl hover:border-gray-300 focus:outline-none focus:border-blue-500 transition-colors"
            >
              <option>Today</option>
              <option>Yesterday</option>
              <option>This Month</option>
              <option>Last Month</option>
              <option>This Year</option>
              <option>Last Year</option>
            </select>

            <input
              placeholder="Start Date"
              onfocus="(this.type='date')"
              class="w-1/3 pl-3 pr-10 py-2 border-2 border-gray-200 rounded-xl hover:border-gray-300 focus:outline-none focus:border-blue-500 transition-colors"
            />
            <input
              class="w-1/3 pl-3 pr-10 py-2 border-2 border-gray-200 rounded-xl hover:border-gray-300 focus:outline-none focus:border-blue-500 transition-colors"
              placeholder="End Date"
              onfocus="(this.type='date')"
            />
            <button
              type="button"
              class="border w-1/5 font-semiborder-indigo-500 bg-indigo-500 text-white rounded-md transition duration-500 ease select-none hover:bg-indigo-600 focus:outline-none focus:shadow-outline"
            >
              Apply
            </button>
          </div>
        </div>
        <div class="absolute top-0 left-0 w-full h-2 flex">
          <div class="h-1 bg-green-600  flex-1"></div>
          <div class="h-1 bg-yellow-500 flex-1"></div>
          <div class="h-1 bg-green-600  flex-1"></div>
          <div class="h-1 bg-yellow-500 flex-1"></div>
          <div class="h-1 bg-green-600  flex-1"></div>
          <div class="h-1 bg-yellow-500 flex-1"></div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  props: {},
};
</script>
<style scoped>
.min-w-80 {
  min-width: 20rem;
}
.resize::-webkit-resizer,
.resize-x::-webkit-resizer,
.resize-y::-webkit-resizer {
  background-color: transparent;
}
.resize:after,
.resize-x:after,
.resize-y:after {
  display: block;
  position: absolute;
  bottom: 5px;
  right: 5px;
  width: 24px;
  height: 24px;
  content: "\F045D";
  font: normal normal normal 24px/1 "Material Design Icons";
  font-size: 24px;
  text-rendering: auto;
  line-height: 24px;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: rgba(0, 0, 0, 0.3);
}
</style>
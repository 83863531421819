<template>
  <div>
    <div
      class="flex-grow flex flex-col m-4 p-4 bg-white border-t border-b sm:rounded sm:border shadow overflow-hidden"
    >
      <h2 class="font-semibold italic">{{$t('total_orders')}}</h2>
      <canvas id="myChart"></canvas>
    </div>
  </div>
</template>

<script>
import Chart from "chart.js";
export default {
  props: ["chartData"],
  data() {
    return {
      tab: [],
      myChart: "",
    };
  },
  async mounted() {
    await this.drawChart(this.chartData);
  },
  methods: {
    drawChart(chartData) {
      var ctx = document.getElementById("myChart");
      this.myChart = new Chart(ctx, {
        type: "line",
        data: {
          labels: ["Confirmed", "Cancelled", "Unreached", "Skipped"],
          datasets: [
            {
              label: "Totl Orders",
              data: chartData,
              backgroundColor: ["#ff6384", "#36a2eb", "#ffce56", "#4c51bf"],
              borderColor: ["#ff6384", "#36a2eb", "#ffce56"],
              borderWidth: 1,
              weight: 2,
            },
          ],
        },
        options: {
          responsive: true,
          cutoutPercentage: 90,
          maintainAspectRatio: false,

          scales: {
            yAxes: [
              {
                ticks: {
                  beginAtZero: true,
                },
              },
            ],
          },
        },
      });
    },
  },
  watch: {
    chartData: function () {
      if (this.myChart != null) {
        this.myChart.destroy();
      }
      this.drawChart(this.chartData);
    },
  },
};
</script>


<style >
canvas {
  -moz-user-select: none;
  -webkit-user-select: none;
  -ms-user-select: none;
}
</style>
